const textProps = {
  copyright: {
    children: "Software Creative Operations",
    href: "https://www.softcro.com",
    target: "_blank",
  },
  links: [
    {
      children: "About Us",
      to: "/about",
      // target: "_blank",
    },
    {
      children: "Contact Us",
      to: "/contact",
      // target: "_blank",
    },
  ],
};
export default textProps;
