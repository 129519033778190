import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppContext } from 'context';
import { RenderIf } from 'components';
// components
import Skew from '../../../components/Skews/Skew.js';
// texts as props
import projects1 from '_texts/e-commerce/projects/projects1.js';
//icons
import { BsTwitter, BsFillPlayFill } from 'react-icons/bs';
import { MdOutlineScreenSearchDesktop } from 'react-icons/md';
import { GiMineWagon } from 'react-icons/gi';
import { ImQrcode, ImFacebook, ImMobile2 } from 'react-icons/im';
import { IoShareSocialSharp, IoLogoWhatsapp } from 'react-icons/io5';
import { SiTiktok, SiGoogleads } from 'react-icons/si';
import {
  AiFillInstagram,
  AiFillThunderbolt,
  AiOutlineGoogle,
  AiFillTool,
} from 'react-icons/ai';
import { FaPinterestP, FaStripeS, FaBloggerB } from 'react-icons/fa';
import { RiLinkedinFill, RiCodeSSlashFill } from 'react-icons/ri';
import { Button } from '@material-tailwind/react';

export default function HeaderBlogPost({
  image,
  title,
  description,
  icon,
  color,
  skew,
}) {
  const iconColor = {
    blueGray: 'bg-blueGray-500 text-white',
    red: 'bg-red-500 text-white',
    orange: 'bg-orange-700 text-white',
    amber: 'bg-amber-500 text-white',
    emerald: 'bg-emerald-500 text-white',
    teal: 'bg-teal-500 text-white',
    lightBlue: 'bg-lightBlue-500 text-white',
    indigo: 'bg-indigo-500 text-white',
    purple: 'bg-purple-500 text-white',
    pink: 'bg-pink-500 text-white',
    black: 'bg-black text-white',
  };
  const iconBgColor = {
    blueGray: 'bg-blueGray-500',
    red: 'bg-red-500',
    orange: 'bg-orange-500',
    amber: 'bg-amber-500',
    emerald: 'bg-emerald-500',
    teal: 'bg-teal-500',
    lightBlue: 'bg-lightBlue-500',
    indigo: 'bg-indigo-500',
    purple: 'bg-purple-500',
    pink: 'bg-pink-500',
  };
  const icons = {
    website: <RiCodeSSlashFill />,
    maintenance: <AiFillTool />,
    seo: <MdOutlineScreenSearchDesktop />,
    blog: <FaBloggerB />,
    stripe: <FaStripeS />,
    datamining: <GiMineWagon />,
    qrbarcodes: <ImQrcode />,
    pages: <IoShareSocialSharp />,
    google: <AiOutlineGoogle />,
    instagram: <AiFillInstagram />,
    tiktok: <SiTiktok />,
    whatsapp: <IoLogoWhatsapp />,
    twitter: <BsTwitter />,
    pinterest: <FaPinterestP />,
    linkedin: <RiLinkedinFill />,
    googleAds: <SiGoogleads />,
    youtube: <BsFillPlayFill />,
    facebook: <ImFacebook />,
    messenger: <AiFillThunderbolt />,
    mobile: <ImMobile2 />,
  };

  const { service = 'Services' } = useAppContext();

  const selectedCard = projects1.cards.find(({ title }) => title === service);

  return (
    <RenderIf isTrue={service}>
      <div className="p-0 flex items-center overflow-hidden relative h-96">
        <div
          className="absolute w-full h-full bg-cover bg-50 z-2"
          style={{ backgroundImage: `url('${image}')` }}
        />
        <div className="w-full absolute text-white bottom-0 z-3">
          <Skew {...skew} />
        </div>
      </div>
      <RenderIf isTrue={selectedCard}>
        <div className="w-full md:container md:mx-auto px-0 md:px-4 z-3 -mt-64 pt-20 relative md:w-8/12">
          <div className="justify-center flex flex-wrap md:-mx-4">
            <div className="bg-white rounded-lg shadow-lg">
              <div className="text-center -mt-12">
                <div
                  className={`${
                    iconColor[selectedCard?.color]
                  } rounded-full h-20 w-20 inline-flex
                 items-center justify-center text-4xl`}
                >
                  {icons[selectedCard?.icon]}
                </div>
              </div>
              <div className="p-6">
                <h1
                  className={`w-full text-center text-2xl font-bold mt-0 mb-3 py-0 px-4 text-grey-800`}
                >
                  {service}
                </h1>
                <p className="py-2 px-6 md:px-12 text-lg leading-relaxed text-left text-blueGray-500">
                  {selectedCard?.description}
                </p>
                {selectedCard?.descriptionExtended?.map((paragraph) => (
                  <p className="py-2 px-6 md:px-12 text-lg leading-relaxed text-left text-blueGray-500">
                    {paragraph}
                  </p>
                ))}
                <div className="w-full my-6 flex justify-center items-center">
                  <Link to="/contact">
                    <Button
                      size="md"
                      className={`${iconColor[selectedCard?.color]}`}
                    >
                      Learn More
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderIf>
    </RenderIf>
  );
}

HeaderBlogPost.defaultProps = {
  color: 'blueGray',
  skew: {},
};

HeaderBlogPost.propTypes = {
  // background image source
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  // the background color of the icon
  color: PropTypes.oneOf([
    'blueGray',
    'red',
    'orange',
    'amber',
    'emerald',
    'teal',
    'lightBlue',
    'indigo',
    'purple',
    'pink',
  ]),
  // props to pass to the Skew component
  skew: PropTypes.object,
};
