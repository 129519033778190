import CardLoginSocial from "./CardLoginSocial";
import CardRegister from "./CardRegister";
import CardResetPassword from "./CardResetPassword";
import CardLoginRegister from "./CardLoginRegister";

const componentsArray = [
  CardLoginSocial,
  CardRegister,
  CardResetPassword,
  CardLoginRegister,
];
export default componentsArray;
