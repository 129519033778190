import React from 'react';
import PropTypes from 'prop-types';
// components
import Heading2Badge from 'components/Headings/Heading2Badge.js';
import { InlineWidget } from 'react-calendly';

export default function Features2({ heading2badge, image, cards }) {
  return (
    <div
      className="relative my-12 bg-blueGray-800 text-center pt-20"
      style={{ backgroundImage: 'url(' + image + ')' }}
    >
      <div className="w-full md:w-9/12 lg:w-3/5 h-full pb-20 md:pb-0 mx-auto px-4">
        {heading2badge && <Heading2Badge {...heading2badge} />}
        <div style={{ height: '100vh' }}>
          <InlineWidget
            url="https://calendly.com/softcro-dev/30min"
            styles={{ width: '100%', height: '100%' }}
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: '00a2ff',
              textColor: '4d5055',
            }}
          />
        </div>
      </div>
    </div>
  );
}

Features2.defaultProps = {
  cards: [],
  color: 'blueGray',
  heading2badge: null,
};
Features2.propTypes = {
  // prop to pass to the Heading2Badge component
  heading2badge: PropTypes.object,
  image: PropTypes.string,
  // array of props to pass to the CardInfoIconLeft component
  cards: PropTypes.arrayOf(PropTypes.object),
};
