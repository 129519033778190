import React from 'react';
import Team1Picture from 'assets/img/team1.jpg';
import img from 'assets/img/p2.png';
import Badge from 'components/Elements/Badge.js';

export default function HeaderNewsletterIllustration() {
  return (
    <div className="relative">
      <div className="flex items-center absolute top-0 right-0 min-h-1280-px h-1280-px max-h-1600-px mb-50-px opacity-10 -z-10">
        <img alt="..." className="w-full" src={img} />
      </div>

      <div className="p-0 flex items-center overflow-hidden min-h-screen">
        <div className="w-11/12 md:w-9/12 mx-auto px-4 my-0 md:my-32">
          {/*  Primer Block  */}
          <div className="text-left flex flex-wrap items-start -mx-2 sm:my-3 md:mt-6 md:mb-56">
            <div className="mt-12 sm:px-0 md:px-12 relative w-full lg:w-6/12 text-left flex flex-col justify-center items-start">
              <Badge color="purple">The Who</Badge>
              <h2 className="text-4xl font-bold text-blueGray-700 my-3 w-full text-left">
                Who is Softcro?
              </h2>
              <p className="mb-4 leading-relaxed text-blueGray-500">
                We are a community-leading provider of innovative software
                solutions and cutting-edge marketing services, dedicated to
                helping businesses across industries achieve their full
                potential. At Softcro, we specialize in assisting companies of
                all sizes in leveraging the power of technology and data-driven
                strategies to meet their growth objectives. By delivering
                personalized, high-impact solutions, we ensure that each
                client’s unique needs are met effectively. Our expertise spans
                advanced software development, seamless AI integration, and
                comprehensive digital marketing, all designed to propel your
                business forward in today’s competitive landscape. At Softcro,
                we don’t just offer services; we partner with you to unlock new
                opportunities, optimize performance, and keep your business
                ahead of the curve.
              </p>{' '}
            </div>
            <div className="hidden md:block relative px-4 w-full mt-12 lg:mt-0 lg:w-6/12">
              <img
                src={Team1Picture}
                className="w-full"
                alt="Team 1 picture"
                style={{
                  borderTopRightRadius: '250px',
                  borderBottomLeftRadius: '250px',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
