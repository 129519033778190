const faqProps = {
    header: "FAQ",
    questions: [
        {
            question: "What services does your company offer for software development?",
            answer: `Our team offers custom software development services, from the initial concept and research 
            phase to deployment and maintenance. We work with businesses to understand their unique needs and
            goals and deliver innovative software solutions that drive growth.`,
        },
        {
            question: "How does your company approach software development projects?",
            answer: `Our approach is methodical and thorough, with a focus on collaboration and communication. 
            We work closely with clients throughout the software development life cycle to ensure that their 
            needs are met and their goals are exceeded.`
        },
        {
            question: "Can you provide examples of industries your company has worked with?",
            answer: `Our team has extensive experience across a variety of industries, including healthcare, 
            finance, retail, and technology. We are well-equipped to understand and meet the needs of businesses
             in any industry.`,
        },
        {
            question: "What technologies does your company use for software development?",
            answer: `Our developers are proficient in a range of technologies, including Python, Node JS, JavaScript, 
            and JavaScript frameworks such as React JS, among others. We are able to use the technology that is best suited to meet
            the specific needs of each project.`,
        },
        {
            question: "What is the timeline for a typical software development project?",
            answer: `The timeline for each software development project will vary depending on its scope and 
            complexity. Our team will work with clients to establish a timeline that is realistic and achievable,
             taking into account all necessary steps in the software development life cycle.`,
        },
        {
            question: "What is your company's process for ensuring software quality?",
            answer: `Quality is a top priority for our team, and we have rigorous processes in place to ensure 
            that all software we deliver is of the highest quality. This includes regular testing, code reviews,
             and user acceptance testing to ensure that the software meets all requirements and expectations.`,
        },
    ]
}

export default faqProps;