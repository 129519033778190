import React from 'react';
import PropTypes from 'prop-types';
import { PopupButton } from 'react-calendly';
// components
import Heading3Badge from '../../../../components/Headings/Heading3Badge.js';

export default function Pricing7({ heading3badge, table }) {
  const colors = {
    blueGray: 'text-blueGray-500',
    red: 'text-red-500',
    orange: 'text-orange-500',
    amber: 'text-amber-500',
    emerald: 'text-emerald-500',
    teal: 'text-teal-500',
    lightBlue: 'text-lightBlue-500',
    indigo: 'text-indigo-500',
    purple: 'text-purple-500',
    pink: 'text-pink-500',
  };
  return (
    <>
      <div className="mb-12">
        <div className="container mx-auto px-4">
          {heading3badge && <Heading3Badge {...heading3badge} />}
          <div className="items-center flex flex-wrap -mx-4">
            <div className="px-4 relative w-full">
              <div className="block w-full overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead className="text-blueGray-700">
                    <tr className="border-b">
                      {table.head.map(
                        (prop, key) =>
                          (typeof prop === 'string' && (
                            <th
                              key={key}
                              className={
                                (key === 0 ? 'text-left' : 'text-center') +
                                ' py-2 align-bottom p-3 border-0'
                              }
                            >
                              <h5 className="text-2xl font-normal leading-normal mt-0 mb-2">
                                {prop}
                              </h5>
                            </th>
                          )) || (
                            <th
                              key={key}
                              className="text-center py-4 p-3 border-0"
                            >
                              <p className="text-blueGray-500 mb-0">
                                {prop.subtitle}
                              </p>
                              {/* <h4 className="text-xl leading-tight mt-0 mb-6">
                                {prop.title}
                              </h4> */}
                              <div
                                className={`bg-white text-emerald-500 border-emerald-500 
                                active:border-emerald-600 cursor-pointer focus:outline-none mt-2 mb-1 text-xl font-black`}
                              >
                                <PopupButton
                                  className="focus:outline-none"
                                  id="popup-button"
                                  url="https://calendly.com/softcro-dev/30min"
                                  /*
                                   * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                   * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                   */
                                  rootElement={document.getElementById('root')}
                                  text="Contact"
                                  // textColor="#ffffff"
                                  // color="#00a2ff"
                                />
                              </div>
                              {/* <Button {...prop.button} /> */}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="text-blueGray-500">
                    {table.body.map((prop, key) => (
                      <tr key={key}>
                        {(prop || []).map(
                          (colProp, colKey) =>
                            (typeof colProp === 'string' && (
                              <td
                                key={colKey}
                                className={
                                  (colKey !== 0
                                    ? 'text-center '
                                    : 'px-3 text-left ') + 'py-4'
                                }
                              >
                                {colProp}
                              </td>
                            )) || (
                              <td key={colKey} className="text-center py-4">
                                <i
                                  className={
                                    colors[colProp.color] +
                                    ' text-lg ' +
                                    colProp.icon
                                  }
                                ></i>
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="w-full flex flex-col justify-start items-center">
                  <div
                    className={`text-white bg-emerald-500 border-emerald-500 
                    active:border-emerald-600 cursor-pointer focus:outline-none mb-1 text-xl font-black mt-12 px-6 py-1 rounded-md`}
                  >
                    <PopupButton
                      className="focus:outline-none"
                      id="popup-button"
                      url="https://calendly.com/softcro-dev/30min"
                      /*
                       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                       */
                      rootElement={document.getElementById('root')}
                      text="Contact"
                      // textColor="#ffffff"
                      // color="#00a2ff"
                    />
                  </div>
                  <h3 className="text-3xl font-bold my-3 text-blueGray-700">
                    Customize Your Package
                  </h3>
                  <p className="text-xl leading-relaxed text-blueGray-500 mb-36">
                    Create the perfect mix of services. Click 'Contact' to get
                    started!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Pricing7.defaultProps = {
  table: {
    head: [],
    body: [],
  },
  heading3badge: null,
};
Pricing7.propTypes = {
  // prop to pass to the Heading3Badge component
  heading3badge: PropTypes.object,
  table: PropTypes.shape({
    head: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          title: PropTypes.string,
          subtitle: PropTypes.string,
          // props to pass to the Button component
          button: PropTypes.object,
        }),
      ])
    ),
    body: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            icon: PropTypes.string,
            color: PropTypes.oneOf([
              'blueGray',
              'red',
              'orange',
              'amber',
              'emerald',
              'teal',
              'lightBlue',
              'indigo',
              'purple',
              'pink',
            ]),
          }),
        ])
      )
    ),
  }),
};
