import React, { Fragment, useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const Faq = ({ header, questions }) => {
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    return (
        <div className="w-100 mx-6 md:w-1/2 md:mx-auto pb-24 text-left">
            <h6 className="w-100 flex justify-center mt-24 mb-2 text-3xl font-bold uppercase text-blueGray-700">
                {header}
            </h6>
            <Fragment>
                {questions.map(({ question, answer }, index) => (
                    <Accordion
                        open={open === index + 1}
                        onClick={() => handleOpen(index + 1)}
                        key={question}
                    >
                        <AccordionHeader className="text-left px-3 focus:outline-none">{question}</AccordionHeader>
                        <AccordionBody className='text-base px-3'>{answer}</AccordionBody>
                    </Accordion>
                ))}
            </Fragment>
        </div>
    )
}

export default Faq