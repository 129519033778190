const textProps = {
  title: "Software Creative Operations.",
  subtitle: "Copyright © 2022 All rights reserved.",
  socials: [
    {
      pathname: "https://www.facebook.com/softcro.devs",
      icon: "fab fa-facebook",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
    {
      pathname: "https://www.linkedin.com/company/softcro",
      icon: "fab fa-linkedin",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
    {
      pathname: "https://github.com/Software-Creative-Operations",
      icon: "fab fa-github",
      buttonProps: {
        color: "white",
        outline: false,
        size: "sm",
      },
    },
  ],
};
export default textProps;
