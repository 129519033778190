import React from 'react';
import { Link } from 'react-router-dom';

export default function Blogs4({ data }) {
  return (
    <div className="w-10/12 md:w-1/2 mx-auto">
      {data?.article &&
        data?.article?.map((paragraph, index) => {
          return (
            <section key={index} className="pt-12">
              <p key={index} className="mb-4 text-lg text-blueGray-500">
                {paragraph}
              </p>
            </section>
          );
        })}

      <div className="w-full flex justify-center mt-24 mb-64">
        <Link
          to="/contact"
          className={`my-6 inline-flex text-sm px-6 py-2 shadow outline-none focus:outline-none align-middle 
            transition-all duration-150 ease-in-out uppercase border border-solid font-bold rounded-md 
            hover:shadow-md text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900`}
        >
          Schedule a Call Now!
        </Link>
      </div>
    </div>
  );
}
