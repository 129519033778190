const textProps = {
  content: [
    "Awesome, blog is important source material for anyone who creates apps?",
    "Because these blogs offer a lot of information about website development.",
  ],
  time: {
    icon: "fas fa-check",
    text: "4:46am",
  },
  color: "lightBlue",
  link: {
    href: "#pablo",
  },
  align: "right",
};
export default textProps;
