const textProps = {
  title: 'Freelancer Package',
  description: 'Lifetime Access',
  price: '$2000',
  list: [
    {
      icon: 'fas fa-folder-open',
      text: 'Complete documentation',
    },
    {
      icon: 'fab fa-sketch',
      text: 'Working materials in Sketch',
    },
    {
      icon: 'fas fa-archive',
      text: 'Unlimited cloud storage',
    },
  ],
  button: {
    color: 'red',
    children: 'Buy Now',
  },
  link: {
    href: '#pablo',
    children: 'More details...',
  },
};
export default textProps;
