import team1 from 'assets/img/team-1-800x800.jpg';

const textProps = {
  image: team1,
  name: 'Ryan Tompson',
  jobTitle: 'Web Developer',
  socialLinks: [
    {
      icon: 'twitter',
      link: '#pablo',
    },
    {
      icon: 'facebook',
      link: '#pablo',
    },
    {
      icon: 'dribbble',
      link: '#pablo',
    },
  ],
};
export default textProps;
