import { useState, useEffect } from "react";

function useScrollY() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        // Handler to call on window resize
        function handleScroll() {
            // Set window width/height to state
            setScrollPosition(window.scrollY);
        }
        // Add event listener
        window.addEventListener("scroll", handleScroll);
        // Call handler right away so state gets updated with initial window size
        handleScroll();
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleScroll);
    }, []); // Empty array ensures that effect is only run on mount
    return scrollPosition;
}

export default useScrollY;