import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// components
import Heading2 from "../../../../components/Headings/Heading2.js";
import Heading3 from "../../../../components/Headings/Heading3.js";
import HeadingBadge from "../../../../components/Headings/Heading3Badge.js";
import InfoAreaIcon from "../../../../components/InfoAreas/Presentation/InfoAreaIcon.js";
import CardContactUsIcons from "../../../../components/Cards/Presentation/CardContactUsIcons.js";
import img from "assets/img/ill_header.png";

export default function ContactUs2({ image, heading2, button, infoAreas, card }) {
  return (
    <>
      <div className="overflow-hidden py-0 bg-blueGray-100 min-h-screen">
        <div className="flex items-center absolute top-0 right-0 min-h-400-px h-400-px max-h-999-px mb-50-px opacity-10">
          <img alt="..." className="w-full" src={img} />
        </div>
        <div className="w-100 flex justify-center">
          <div className="mt-32 md:mt-36 md:w-3/4 mx-6">
            {heading2 && <Heading3 {...heading2} />}
          </div>
        </div>
        <div className="mx-auto px-4">
          {/* <div className="flex flex-wrap -mx-4 items-center justify-center">
            <Button {...button} />
          </div> */}
          <div className="flex flex-wrap">
            <div className="mx-auto px-4 relative w-full lg:w-6/12">
              <CardContactUsIcons {...card} />
            </div>
          </div>
          <div className="w-100 h-96 flex justify-center items-center -mx-4 mt-0">
            {infoAreas.map((prop, key) => (
              <div
                key={key}
                className={classnames("px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12",
                  { "border-r": key !== infoAreas.length - 1 }
                )}
              >
                <InfoAreaIcon {...prop} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
ContactUs2.defaultProps = {
  button: {},
  infoAreas: [],
  heading2: null,
};
ContactUs2.propTypes = {
  image: PropTypes.string,
  // prop to pass to the Heading2 component
  heading2: PropTypes.object,
  // it is represented by the props
  // that can be passed to the Button,
  // so please check that one out
  button: PropTypes.object,
  // it is an array that has objects which
  // are represented by the props
  // that can be passed to the InfoAreaIcon,
  // so please check that one out
  infoAreas: PropTypes.arrayOf(PropTypes.object),
  card: PropTypes.object,
};
