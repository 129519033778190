import HeaderImageTitleCenter from "./HeaderImageTitleCenter.js";
import HeaderVideoTitle from "./HeaderVideoTitle.js";
import HeaderNewsletterIllustration from "./HeaderNewsletterIllustration.js";

const componentsArray = [
  HeaderImageTitleCenter,
  HeaderVideoTitle,
  HeaderNewsletterIllustration,
];
export default componentsArray;
