const textProps = {
  icon: "fab fa-twitter",
  title: "twitter",
  subtitle: "@creativetim",
  color: "twitter",
  link: {
    href: "#pablo",
  },
};
export default textProps;
