import React from "react";
import { useLocation } from "react-router-dom";

// sections
import Pricing4Presentation from "components/Sections/Presentation/Pricing/Pricing4Presentation";
// components
import NavbarLinks from "components/Navbars/NavbarLinks.js";
import PreFooterLarge from "components/PreFooters/PreFooterLarge.js";
import FooterSmall from "components/Footers/Landing/FooterSmall.js";
import Faq from "components/Sections/Presentation/FAQ/Faq.js";
// texts as props
import pricing4presentationProps from "_texts/presentation/pricing/price4presentation.js";
import navbarlinkspricing from "_texts/e-commerce/navbars/navbarlinkspricing.js";
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import footersmall from "_texts/presentation/footers/footersmall.js";
import faqProps from "_texts/presentation/faq/faq.js";


export default function Pricing() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const componentProps = {
    ...pricing4presentationProps,
    header: '',
    subheader: `Full Digital Coverage Packages`,
    description: `Put all your company's digital activity on autopilot today.`,
    description2: `We take over the complexity out of technology and put it to work for you!`,
  }

  return (
    <>
      <div>
        <NavbarLinks {...navbarlinkspricing} />
        <div className="pt-16 bg-blueGray-50">
          <Pricing4Presentation {...componentProps} />
          <Faq {...faqProps} />
        </div>
      </div>
      <PreFooterLarge {...prefooterlarge} />
      <FooterSmall {...footersmall} />
    </>
  );
}
