import React from "react";

// import Frameworks from "_docs/components/Frameworks2.js";
//
// const frameworks = [
//   {
//     image:
//       "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/angular.jpg",
//     text: "Comming soon...",
//     link: "#pablo",
//     color: "angular",
//   },
//   {
//     image:
//       "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/js.png",
//     text: "Comming soon...",
//     link: "#pablo",
//     color: "javascript",
//   },
//   {
//     image:
//       "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/nextjs.jpg",
//     text: "Comming soon...",
//     link: "#pablo",
//     color: "nextjs",
//   },
//   {
//     image:
//       "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/react.jpg",
//     text: "React",
//     link: "https://www.creative-tim.com/product/notus-pro-react",
//     color: "react",
//   },
//   {
//     image:
//       "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/svelte.jpg",
//     text: "Comming soon...",
//     link: "#pablo",
//     color: "svelte",
//   },
//   {
//     image:
//       "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/vue.jpg",
//     text: "Comming soon...",
//     link: "#pablo",
//     color: "vuejs",
//   },
// ];

export default function Index() {
  return (
    <>
      <div className="flex flex-row pt-20 justify-center"></div>
    </>
  );
}
