import infoareaicontitlelistcolor from '_texts/presentation/infoarea/infoareaicontitlelistcolor.js';

const textProps = {
  heading2: {
    color: 'emerald',
    subtitle: 'Time passage',
    title: 'In a Decade of Growth',
    description:
      'Through this journey, we’ve gained invaluable experience across different niches and have honed in on specific areas where our expertise truly shines.',
    alignment: 'left',
  },
  infoArea: { ...infoareaicontitlelistcolor },
  cards: [
    {
      color: 'teal',
      icon: 'fas fa-bullseye',
      title: 'Targeted Marketing',
      description:
        'We specialize in pinpointing your ideal audience, ensuring every marketing dollar is spent effectively.',
      gradient: false,
    },
    {
      color: 'red',
      icon: 'fas fa-tags',
      title: 'Niche Specialization',
      description:
        'Our deep focus on specific niches allows us to craft strategies that resonate and convert.',
      gradient: false,
    },
    {
      color: 'dark',
      icon: 'fas fa-chart-line',
      title: 'ROI Focused',
      description:
        'We prioritize maximizing your return on investment by delivering measurable results.',
      gradient: false,
    },
    {
      color: 'lightBlue',
      icon: 'fas fa-handshake',
      title: 'Pay for Results',
      description:
        'We charge only when you see the results, ensuring your investment is always worthwhile.',
      gradient: false,
    },
    {
      color: 'orange',
      icon: 'fas fa-users',
      title: 'Multiply Customers',
      description:
        'We grow your business by multiplying your customer base through proven strategies.',
      gradient: false,
    },
    {
      color: 'emerald',
      icon: 'fas fa-eye',
      title: 'Behavior Tracking',
      description:
        'We track and analyze customer behavior to continuously refine and optimize your marketing efforts.',
      gradient: false,
    },
  ],
};

export default textProps;
