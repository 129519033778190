import video from "assets/videos/Lights.mp4";

const textProps = {
  video,
  title: "Software Solutions for Small Businesses",
  subtitle:
    "Empowering businesses with innovative technology, our team provides tailored software development services to meet unique needs and drive growth.",
  buttonColor: "red",
  buttonText: "Book a Meeting",
  buttonIcon: "fas fa-calendar-alt",
  skew: {
    color: "white",
  },
};
export default textProps;
