import image from 'assets/img/team1.jpg';

const textProps = {
  data: [
    {
      title: 'Who we are',
      description: `We are a dynamic, full-service marketing agency dedicated to helping businesses across various sectors, including retail, services, and industrial/manufacturing, achieve their growth potential through innovative and tailored digital strategies. Our expertise spans a wide range of services designed to elevate your brand, engage your audience, and drive measurable results. From Search Engine Optimization (SEO) that boosts your online visibility to Paid Media strategies that amplify your reach through targeted advertising, we offer comprehensive solutions that cater to your unique business needs. Our Brand & Content services are crafted to create consistent, engaging messages that build and reinforce your brand identity, while our Marketing Amplification ensures your efforts reach the right audience through social media management, email marketing, and influencer partnerships. Our Creative team brings your vision to life with cutting-edge graphic design, UX/UI design, and video marketing that captivate and convert. For businesses looking to innovate, our Software & Automation services provide custom application development, CRM integration, and AI-driven solutions that streamline operations and enhance efficiency. Whether you’re a small business looking to scale or an established company aiming to maintain your competitive edge, we deliver proven configurations and strategies that are customized to your specific goals, helping you thrive in today’s fast-paced digital landscape.`,
      subdescription: ``,
      image: image,
    },
  ],
};
export default textProps;
