import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Chip } from '@material-tailwind/react';
import classnames from 'classnames';
import { RenderIf } from 'components';
import useWindowSize from 'hooks/useWindowSize';

export default function Pricing4({
  header,
  subheader,
  description,
  description2,
  packages,
  buyButtonText,
  secondOfferHeader,
  secondOfferSubheader,
  secondOfferLeftButton,
  secondOfferRightButton,
}) {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <>
      <div className="py-20 bg-blueGray-50">
        <div className="container mx-auto px-4">
          <div className="mb-12 flex flex-wrap justify-center">
            <div className="px-4 relative w-full lg:w-8/12 text-center">
              <h6 className="mb-2 text-lg font-bold uppercase text-emerald-500">
                {header}
              </h6>
              <h2 className="text-4xl font-bold mt-0 mb-1 text-blueGray-700">
                {subheader}
              </h2>
              <p className="mt-2 text-xl leading-relaxed text-blueGray-400">
                {description}
              </p>
              <p className="mt-0 mb-4 text-xl leading-relaxed text-blueGray-400">
                {description2}
              </p>
            </div>
          </div>

          <div className="flex flex-wrap md:-mx-4">
            {packages.map(
              (
                {
                  header,
                  price,
                  suggestion,
                  paymentCycle,
                  features,
                  buttonLink,
                  bestPackage,
                },
                key
              ) => (
                <div className="px-1 relative w-full md:w-1/4" key={key}>
                  <div
                    className={classnames(
                      `mb-6 text-center shadow-lg rounded-xl relative flex flex-col min-w-0 break-words w-full`,
                      {
                        'bg-blueGray-900 text-white': bestPackage,
                        'bg-white': !bestPackage,
                        'px-1': !isMobile,
                        'px-6': isMobile,
                      }
                    )}
                  >
                    <div
                      className={classnames(
                        `px-5 py-3 mx-4 border-b border-opacity-10`,
                        {
                          'border-white': bestPackage,
                          'border-black': !bestPackage,
                        }
                      )}
                    >
                      <h4
                        className={classnames(`font-bold my-2`, {
                          'text-white': bestPackage,
                        })}
                      >
                        {header}
                      </h4>
                    </div>
                    <div
                      className={classnames(`lg:px-10 px-0 flex-auto pb-5`, {
                        'pt-5': !bestPackage,
                        'pt-3': bestPackage,
                      })}
                    >
                      <div
                        className={classnames(
                          `text-4xl leading-tight font-bold`,
                          {
                            'text-5xl text-white': bestPackage,
                          }
                        )}
                      >
                        {price}
                      </div>
                      <span className="text-sm text-blueGray-500 flex flex-col justify-center items-center">
                        <div>{paymentCycle}</div>
                        <div>{suggestion}</div>
                      </span>
                      <ul
                        className={classnames(`mb-8 list-none`, {
                          'mt-6': bestPackage,
                          'mt-8': !bestPackage,
                        })}
                      >
                        {features.map(
                          ({
                            text,
                            icon,
                            active,
                            quantity,
                            divider = false,
                          }) => (
                            <Fragment key={text}>
                              <RenderIf isTrue={divider}>
                                <li
                                  className={classnames(
                                    `mt-3 mb-6 border border-b-1`,
                                    {
                                      'border-grey-200': !bestPackage,
                                      'border-grey-800': bestPackage,
                                    }
                                  )}
                                />
                              </RenderIf>
                              <RenderIf isTrue={!divider}>
                                <li className="pb-2 w-full text-left">
                                  <div className="w-full flex items-center mb-2">
                                    <div
                                      className={classnames({
                                        'opacity-75': active,
                                        'opacity-25': !active,
                                      })}
                                    >
                                      <Chip
                                        value={quantity}
                                        color={
                                          bestPackage ? `amber` : `light-blue`
                                        }
                                        variant="gradient"
                                        className="mr-2"
                                      />
                                    </div>
                                    <span
                                      className={classnames(`text-xs w-full`, {
                                        'text-grey-300': bestPackage && active,
                                        'text-grey-800': !bestPackage && active,
                                        'text-grey-400':
                                          !bestPackage && !active,
                                        'text-grey-700': bestPackage && !active,
                                      })}
                                    >
                                      {text}
                                    </span>
                                  </div>
                                </li>
                              </RenderIf>
                            </Fragment>
                          )
                        )}
                      </ul>
                      <Link
                        to="/contact"
                        className={`
                                            mb-2 inline-flex text-sm px-6 py-2 shadow outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold rounded-md
                                            ${
                                              bestPackage
                                                ? `last:mr-0 mr-2  text-blueGray-800 bg-white border-white active:bg-blueGray-100 active:border-blueGray-100  hover:shadow-lg`
                                                : `hover:shadow-md text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900`
                                            }`}
                      >
                        {buyButtonText}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          <div className="w-full flex flex-wrap flex-col justify-center items-center">
            <h2 className="mt-12 text-4xl font-bold mb-1 text-blueGray-700">
              {secondOfferHeader}
            </h2>
            <p className="text-blueGray-600 mt-2 mx-auto">
              {secondOfferSubheader}
            </p>
            <div className="flex">
              <Link
                to="/services"
                className={`my-6 inline-flex text-sm px-6 py-2 shadow outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold rounded-md last:mr-0 mr-3 text-blueGray-800 bg-white border-white active:bg-blueGray-100 active:border-blueGray-100 hover:shadow-lg`}
              >
                {secondOfferLeftButton}
              </Link>
              <Link
                to="/contact"
                className={`my-6 inline-flex text-sm px-6 py-2 shadow outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold rounded-md hover:shadow-md text-white bg-blueGray-800 border-blueGray-800 active:bg-blueGray-900 active:border-blueGray-900`}
              >
                {secondOfferRightButton}
              </Link>
            </div>
            ``
          </div>
        </div>
      </div>
    </>
  );
}

Pricing4.defaultProps = {
  cards: [],
  buttons: [],
  heading2: null,
};
Pricing4.propTypes = {
  // prop to pass to the Heading2 component
  heading2: PropTypes.object,
  // array of props to pass to the Button component
  buttons: PropTypes.arrayOf(PropTypes.object),
  // array of props to pass to the CardPricingDetails component
  cards: PropTypes.arrayOf(PropTypes.object),
};
