import cardblogfullbg from "_texts/presentation/cards/cardblogfullbg.js";
import thomas from "assets/img/sections/thomas.jpg";
import ashim from "assets/img/sections/ashim.jpg";
import dane from "assets/img/sections/dane.jpg";

const textProps = {
  heading3: {
    color: "emerald",
    subtitle: "Our latest blogposts",
    title: "Check our latest blogposts",
    description:
      "Read our latest blog posts and case studies on companies we've worked with!",
    alignment: "center",
  },
  cards: [
    {
      ...cardblogfullbg,
      size: "md",
    },
    {
      image: thomas,
      subtitle: "Spectrum Boats",
      title: "Data Virtualization and Boats Startups",
      size: "md",
      link: { to: "/blog-post" },
    },
    {
      image: ashim,
      subtitle: "Flying on pandemic",
      title: "New Challenges when you want to fly to new areas",
      size: "md",
      link: { to: "/blog-post" },
    },
    {
      image: dane,
      subtitle: "Work from Home",
      title: "How meetings and behaviour are changing",
      size: "md",
      link: { to: "/blog-post" },
    },
  ],
};
export default textProps;
