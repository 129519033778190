const props = {
    title: 'Bye bye slow software !',
    subtitle: `Good software is the best tool for your business.`,
    description: `Our software is completely designed and written in-house. 
    From a simple Stripe payment to complex merchant solutions, we've got you covered.`,
    features: [
        {
            icon: 'fa-light fa-gauge',
            title: `We don't use slow php based code such as Wordpress.`,
        },
        {
            icon: 'fa-code',
            title: `Our apps are built with modern Javascript libraries.`,
        },
        {
            icon: 'fa-brain',
            title: `We use artificial intelligence to build your software.`,
        },
        {
            icon: 'fa-brush',
            title: `Easy to users oriented designs.`,
        },
    ]
}

export default props;