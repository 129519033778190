const textProps = {
  heading3badge: {
    badge: { color: 'emerald', children: 'Tailored Packages' },
    title: 'Flexible Solutions for Your Team',
    description:
      'Imagine the perfect bundle of services designed just for you. Explore our packages and click "Contact Us" to discuss the right fit for your needs.',
    alignment: 'center',
  },
  table: {
    head: [
      'Services',
      {
        title: 'Contact',
        subtitle: 'Starter',
        button: {
          children: 'Contact Us',
          color: 'emerald',
          outline: true,
          size: 'sm',
        },
      },
      {
        title: 'Contact',
        subtitle: 'Growth',
        button: {
          children: 'Contact Us',
          color: 'emerald',
          outline: true,
          size: 'sm',
        },
      },
      {
        title: 'Contact',
        subtitle: 'Premium',
        button: {
          children: 'Contact Us',
          color: 'emerald',
          size: 'sm',
        },
      },
      {
        title: 'Contact',
        subtitle: 'Enterprise',
        button: {
          children: 'Contact Us',
          color: 'emerald',
          size: 'sm',
        },
      },
    ],
    body: [
      [
        'Social Media Management',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
      [
        'Ad Campaign Management',
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
      [
        'Content Creation (Graphics, Video)',
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
      [
        'Custom Software Solutions',
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
      [
        'Reporting & Analytics',
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
      [
        'Dedicated Account Manager',
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
      [
        'Campaign Budget Management',
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-times-circle',
          color: 'red',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
        {
          icon: 'fas fa-check-circle',
          color: 'emerald',
        },
      ],
    ],
  },
};

export default textProps;
