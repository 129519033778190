/*eslint-disable*/
import React from 'react';
import { useLocation } from 'react-router-dom';
// components
import Hero7 from 'components/Sections/Blog/Heroes/Hero7.js';
import NavbarLinks from 'components/Navbars/NavbarLinks.js';
import PreFooterLarge from 'components/PreFooters/PreFooterLarge.js';
import FooterSmall from 'components/Footers/Landing/FooterSmall.js';
import HeaderNewsletterIllustration from 'components/Headers/Presentation/HeaderNewsletterIllustration.js';
// texts for components as props
import navbarlinkslanding2 from '_texts/presentation/navbars/navbarlinkslanding2.js';
import prefooterlarge from '_texts/presentation/prefooters/prefooterlarge.js';
import footersmall from '_texts/presentation/footers/footersmall.js';
import hero7 from '_texts/blog/heroes/hero7.js';
import Testimonials3 from 'components/Sections/Misc/Testimonials/Testimonials3';
import Features1 from 'components/Sections/Misc/Features/Features1';
import Features2 from 'components/Sections/Misc/Features/Features2';
import Features3 from 'components/Sections/Misc/Features/Features3';
import Features4 from 'components/Sections/Misc/Features/Features4';
import testimonials3 from '_texts/misc/testimonials/testimonials3';
import features1 from '_texts/misc/features/features1';
import features2 from '_texts/misc/features/features2';
import features3 from '_texts/misc/features/features3';
import features4 from '_texts/misc/features/features4';

import Heading2Badge from 'components/Headings/Heading2Badge.js';
import { InlineWidget } from 'react-calendly';

export default function AboutUs() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="overflow-x-hidden">
      <NavbarLinks {...navbarlinkslanding2} />

      <HeaderNewsletterIllustration />

      <div className="md:-mt-80">
        <Features1 {...features1} />
      </div>

      <Features2 {...features2} />

      <Features3 {...features3} />

      {/* Start Testimonials */}
      <svg viewBox="0 0 1440 160">
        <path
          fill="#F1F5F9"
          fill-opacity="1"
          d="M0,96L80,106.7C160,117,320,139,480,128C640,117,800,75,960,69.3C1120,64,1280,96,1360,112L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
      <Testimonials3 {...testimonials3} />
      <svg viewBox="0 0 1440 160">
        <path
          fill="#F1F5F9"
          fill-opacity="1"
          d="M0,96L80,106.7C160,117,320,139,480,128C640,117,800,75,960,69.3C1120,64,1280,96,1360,112L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
      {/* End Testimonials */}

      <Features4 {...features4} />

      <div className="bg-blueGray-100 text-center pt-20">
        <div className="w-10/12 md:w-9/12 mx-auto md:px-4 pb-20 md:pb-0">
          <Heading2Badge title="Let's Chat!" />
          <div style={{ height: '100vh' }}>
            <InlineWidget
              url="https://calendly.com/softcro-dev/30min"
              styles={{ width: '100%', height: '100%' }}
              pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: '00a2ff',
                textColor: '4d5055',
              }}
            />
          </div>
        </div>
      </div>

      <div className="bg-blueGray-800">
        <Hero7 {...hero7} />
      </div>

      <PreFooterLarge {...prefooterlarge} />
      <FooterSmall {...footersmall} />
    </div>
  );
}
