const props = {
  header: `The How Much`,
  subheader: `Common Packages`,
  description: `We can work together and create your own package based on your budget, you are in the right place.`,
  buyButtonText: 'Learn More',
  secondOfferHeader: 'Create your own package',
  secondOfferSubheader:
    'We offer single services and you can also create your own package',
  secondOfferLeftButton: 'See single services',
  secondOfferRightButton: 'Build my own package',
  packages: [
    {
      header: 'Smart Start',
      price: '$2000',
      paymentCycle: 'Single payment',
      suggestion: 'Single Internet Website',
      features: [
        {
          text: 'Logo creation',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Unlimited Marketing consultations',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Website development 6 pages',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Website hosting',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Website email',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Website domains',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Blogs page',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Blog posts per month (Content Creation)',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Unlimited Website maintenance',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited SEO',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited Website support',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Google ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Google local ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Facebook page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Budged limited Facebook campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Instagram page management',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Budget limited Instagram campaign',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'LinkedIn page management',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Budget limited LinkedIn campaign',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'YouTube channel management',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Video blog productions per month (Content Creation)',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Data mining',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: '',
          icon: '',
          active: null,
          quantity: 0,
          divider: true,
        },
        {
          text: 'Hability to increase blog posts.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
        {
          text: 'Hability to increase social media campaigns as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
        {
          text: 'Hability to increase Video Production as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
      ],
      buttonLink: '#',
      bestPackage: false,
    },
    {
      header: 'Entrepeneur',
      price: '$8000',
      paymentCycle: '/month',
      suggestion: 'Best for small businesses with $500k in annual revenue.',
      features: [
        {
          text: 'Logo creation',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Unlimited Marketing consultations',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Website development unlimited pages',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Website hosting',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Website email',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Website domains',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Blogs page',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Blog posts per month (Content Creation)',
          icon: 'fas fa-check',
          active: true,
          quantity: 4,
        },
        {
          text: 'Unlimited Website maintenance',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited SEO',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited Website support',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Google ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Google local ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Facebook page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Budged limited Facebook campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Instagram page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Budget limited Instagram campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'LinkedIn page management',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Budget limited LinkedIn campaign',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'YouTube channel management',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Video blog productions per month (Content Creation)',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: 'Data mining',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: '',
          icon: '',
          active: null,
          quantity: 0,
          divider: true,
        },
        {
          text: 'Hability to increase blog posts.',
          icon: 'fas fa-check',
          active: false,
          quantity: '√',
        },
        {
          text: 'Hability to increase social media campaigns as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: false,
          quantity: '√',
        },
        {
          text: 'Hability to increase Video Production as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: false,
          quantity: '√',
        },
      ],
      buttonLink: '#',
      bestPackage: true,
    },
    {
      header: 'Success',
      price: '$12000',
      paymentCycle: '/month',
      suggestion:
        'Best for small businesses with $1,000,000 in annual revenue.',
      features: [
        {
          text: 'Unlimited Logo creation as needed',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited Marketing consultations',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Website development unlimited pages',
          icon: 'fas fa-check',
          active: true,
          quantity: 3,
        },
        {
          text: 'Website hosting',
          icon: 'fas fa-check',
          active: true,
          quantity: 3,
        },
        {
          text: 'Website email',
          icon: 'fas fa-check',
          active: true,
          quantity: 6,
        },
        {
          text: 'Website domains',
          icon: 'fas fa-check',
          active: true,
          quantity: 3,
        },
        {
          text: 'Blogs page',
          icon: 'fas fa-check',
          active: true,
          quantity: 3,
        },
        {
          text: 'Blog posts per month (Content Creation)',
          icon: 'fas fa-check',
          active: true,
          quantity: 8,
        },
        {
          text: 'Unlimited Website maintenance',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited SEO',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Unlimited Website support',
          icon: 'fas fa-check',
          active: true,
          quantity: '-',
        },
        {
          text: 'Google ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 2,
        },
        {
          text: 'Google local ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Facebook page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Budged limited Facebook campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Instagram page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Budget limited Instagram campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'LinkedIn page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Budget limited LinkedIn campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'YouTube channel management',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Video blog productions per month (Content Creation)',
          icon: 'fas fa-check',
          active: true,
          quantity: 1,
        },
        {
          text: 'Data mining',
          icon: 'fas fa-check',
          active: false,
          quantity: 0,
        },
        {
          text: '',
          icon: '',
          active: null,
          quantity: 0,
          divider: true,
        },
        {
          text: 'Hability to increase blog posts.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
        {
          text: 'Hability to increase social media campaigns as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
        {
          text: 'Hability to increase Video Production as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
      ],
      buttonLink: '#',
      bestPackage: false,
    },
    {
      header: 'Custom Project',
      price: 'Contact',
      paymentCycle: '-',
      suggestion:
        'Best for businesses that need to develop a one time project.',
      features: [
        {
          text: 'Logo creation as needed',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Unlimited Marketing consultations',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Website development',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Website hosting',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Website email',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Website domains',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Blogs page',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Blog posts per month (Content Creation)',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Unlimited Website maintenance',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Unlimited SEO',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Unlimited Website support',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Google ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Google local ads campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Facebook page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Budged limited Facebook campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Instagram page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Budget limited Instagram campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'LinkedIn page management',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Budget limited LinkedIn campaign',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'YouTube channel management',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Video blog productions per month (Content Creation)',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: 'Data mining',
          icon: 'fas fa-check',
          active: true,
          quantity: 'Any',
        },
        {
          text: '',
          icon: '',
          active: null,
          quantity: 0,
          divider: true,
        },
        {
          text: 'Hability to increase blog posts.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
        {
          text: 'Hability to increase social media campaigns as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
        {
          text: 'Hability to increase Video Production as needed. This will increase your budget.',
          icon: 'fas fa-check',
          active: true,
          quantity: '√',
        },
      ],
      buttonLink: '#',
      bestPackage: false,
    },
  ],
};

export default props;
