import image from "assets/img/phone_generation.jpg";

const textProps = {
  heading2badge: {
    badge: {
      color: "pink",
      children: "The Why",
    },
    title: "We merge AI and Marketing",
    description:
      "We privide Online Marketing and Business Intelligence for your Businesses.",
  },
  leftCardProps: {
    image,
    title: "It ain't rocket science",
    description:
      `In today’s world, the Internet is the most powerful tool for reaching out to your customers. 
      We help you generate leads, convert them into customers, and grow your business,
      while you focus on deliver the best possible product.`,
  },
  rightCardInfoIconProps: {
    color: "amber",
    icon: "fas fa-palette",
    title: "Awesome Design",
    description:
      "We use standard Design Systems that result in beautiful user friendly interfaces.",
    gradient: true,
  },
  rightCardBlogImageProps: {
    color: "lightBlue",
    icon: "fas fa-chart-line",
    title: "Higher Numbers",
    description:
      "Experience higher conversions, higher response rate, and more returning visitors after signing up.",
    gradient: true,
  },
  rightCardContactUsTitleProps: {
    color: "purple",
    icon: "fas fa-running",
    title: "Fast Development",
    description:
      "We develop and manage software in a fast and modular way, with already tested components.",
    gradient: true,
  },
  rightCardInfoImageProps: {
    color: "pink",
    icon: "fas fa-spa",
    title: "Piece Of Mind",
    description:
      "The all in one solution. We take care of all your online struggles while you focus on deliver.",
    gradient: true,
  },
};
export default textProps;
