import React from "react";

import firstInfoArea from "_texts/presentation/infoarea/infoareaicon.js";

const textProps = {
  image: require("assets/img/illustrations/bg_contactus3.svg").default,
  heading2: {
    color: "lightBlue",
    title: "Let’s Talk Goals!",
    description:
      "",
    alignment: "center",
  },
  button: {
    color: "lightBlue",
    children: (
      <>
        <i className="mr-1 fas fa-comment" /> Chat with us
      </>
    ),
  },
  infoAreas: [
    // {
    //   ...firstInfoArea,
    //   color: "lightBlue",
    // },
    // {
    //   ...firstInfoArea,
    //   color: "lightBlue",
    // },
    {
      icon: "fas fa-envelope",
      color: "lightBlue",
      title: "Email",
      description: "softcro.dev@gmail.com",
    },
    {
      icon: "fas fa-mobile-alt",
      color: "lightBlue",
      title: "Phone",
      description: "+1(857) 312 4946",
    },
  ],
  card: {
    inputs: [
      {
        label: "Your Name",
        md: 6,
        input: {
          leftIcon: "fas fa-user-circle",
          placeholder: "ex: John Smith",
          type: "text",
        },
      },
      {
        label: "Job Title",
        md: 6,
        input: {
          leftIcon: "fas fa-book",
          placeholder: "ex: Managing Parter",
          type: "text",
        },
      },
      {
        label: "Email address",
        input: {
          leftIcon: "fas fa-envelope",
          placeholder: "Email address...",
          type: "email",
        },
      },
      {
        label: "Your message",
        input: {
          type: "textarea",
          rows: 6,
        },
      },
    ],
    button: {
      color: "dark",
      children: "Contact Us",
    },
  }
};
export default textProps;
