import unsplashs from 'assets/img/sections/unsplashs.jpg';
import austin from 'assets/img/sections/austin.jpg';

import Emily from 'assets/img/faces/team-4.jpg';
import Sarah from 'assets/img/faces/team-7.jpg';
import Dan from 'assets/img/faces/team-11.jpg';
import Michael from 'assets/img/faces/team-10.jpg';

const textProps = {
  defaultOpened: 0,
  items: [
    {
      image: unsplashs,
      icon: 'fas fa-quote-right',
      description:
        '"We needed a partner who could take our lead generation efforts to the next level, and this team did just that."',
      color: 'lightBlue',
      user: {
        name: 'Dan Held',
        image: Dan,
        position: 'Framing Specialist',
      },
      button: {
        color: 'white',
        children: 'read more',
      },
    },
    {
      image: austin,
      icon: 'fas fa-quote-right',
      description: `Securing immigration clients was challenging, but this team’s efforts delivered outstanding results and a steady flow of new cases.`,
      color: 'purple',
      user: {
        name: 'Emily Rodriguez',
        image: Emily,
        position: 'Immigration Attorney',
      },
      button: {
        color: 'white',
        children: 'read more',
      },
    },
    {
      image: austin,
      icon: 'fas fa-quote-right',
      description: `"Social media ads weren’t bringing in deck-building clients, but this team completely turned things around."`,
      color: 'indigo',
      user: {
        name: 'Narek Manoukian',
        image: Michael,
        position: 'Chiropractor',
      },
      button: {
        color: 'white',
        children: 'read more',
      },
    },
    // {
    //   image: austin,
    //   icon: 'fas fa-quote-right',
    //   description: `"This CRM software just brought us to a whole new level."`,
    //   color: 'pink',
    //   user: {
    //     name: 'Sarah Nguyen',
    //     image: Sarah,
    //   },
    //   button: {
    //     color: 'white',
    //     children: 'read more',
    //   },
    // },
    // {
    //   image: austin,
    //   icon: 'fas fa-quote-right',
    //   description: `"Our lead generation is ridiculous after Softcto took over marketing, is literally double"`,
    //   color: 'orange',
    //   user: {
    //     name: 'Another Hot Girl',
    //     image: Sarah,
    //   },
    //   button: {
    //     color: 'white',
    //     children: 'read more',
    //   },
    // },
  ],
};

export default textProps;
