import illustration1 from 'assets/img/illustrations/ill.png';

const textProps = {
  heading3badge: {
    badge: { color: 'purple', children: 'All Sectors' },
    title: 'Suitable for Every Sector',
    description:
      "No matter where your business falls within the economy—whether you're in the Retail, Services, or Industrial/Manufacturing sector—our marketing services are tailored to meet your unique needs.",
    alignment: 'left',
  },
  image: illustration1,
  cards: [
    {
      icon: 'fa fa-shopping-cart',
      color: 'purple',
      title: 'Retail Sector',
      description:
        'In the Retail Sector, standing out is crucial. Our marketing services help retail businesses increase visibility, attract customers, and boost sales through targeted campaigns and effective branding.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
    {
      icon: 'fas fa-concierge-bell',
      color: 'purple',
      // inverted: true,
      title: 'Services Sector',
      description:
        'The Services Sector relies on trust and expertise. We create marketing strategies that build credibility, enhance client relationships, and ensure your services are top of mind for potential customers.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
    {
      icon: 'fas fa-cogs',
      color: 'purple',
      title: 'Industrial/Manufacturing Sector',
      description:
        'In the Industrial/Manufacturing Sector, efficiency and reliability are key. Our marketing solutions enhance your brand’s visibility and communicate the value of your products to the right audience, driving growth and success.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
  ],
};

export default textProps;
