import infoArea1 from '_texts/presentation/infoarea/infoareawhitetitle.js';

const textProps = {
  heading3: {
    color: 'light',
    subtitle: 'Why Choose Softcro?',
    title: 'Experience Unmatched Growth',
    description:
      'At Softcro, we don’t just deliver services; we deliver results that exceed expectations. With systems that work around the clock, we’re always on, optimizing and refining your marketing strategies to ensure you get 10 times the value of your investment. We’re here to help you grow, automate, and succeed, no matter the hour.',
    alignment: 'center',
    whiteContent: true,
  },
  infoAreas: [
    {
      icon: 'fas fa-rocket',
      type: 'light',
      title: 'Accelerate Your Growth',
      description:
        'Our strategies are designed to supercharge your business growth, ensuring you reach new heights quickly and efficiently.',
    },
    {
      icon: 'fas fa-sync-alt',
      type: 'light',
      title: 'Automated Success',
      description:
        'We build systems that work autonomously, continuously refining and optimizing your marketing efforts without the need for constant oversight.',
    },
    {
      icon: 'fas fa-eye',
      type: 'light',
      title: '24/7 Monitoring',
      description:
        'We never sleep. Our team is constantly monitoring your campaigns, ensuring everything runs smoothly and adjusting strategies in real-time for the best results.',
    },
  ],
};

export default textProps;
