import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'hooks';
import { RenderIf } from 'components';
import { useAppContext } from 'context';

const DropdownHoverNavbar = ({ text, items, navColor }) => {
  const { setService } = useAppContext();
  const { width } = useWindowSize();
  const { push } = useHistory();

  const [render, setRender] = React.useState(true);

  const linkColor = {
    light: 'text-blueGray-800',
    white: 'text-blueGray-800',
    transparent: 'lg:text-white text-blueGray-800',
    dark: 'text-white',
    black: 'text-white',
    blueGray: 'text-white',
    red: 'text-white',
    orange: 'text-white',
    amber: 'text-white',
    emerald: 'text-white',
    teal: 'text-white',
    lightBlue: 'text-white',
    indigo: 'text-white',
    purple: 'text-white',
    pink: 'text-white',
  };

  const clickHandler = (service) => {
    console.log({ service });
    setService(service); // If needed for global state management
    push({
      pathname: '/blog-post',
      state: { ...service },
    });
    setRender(false);
  };

  return (
    <>
      <RenderIf isTrue={width > 1024}>
        <div
          className="inline-block group"
          onMouseEnter={() => setRender(true)}
        >
          <a
            className={`hover:opacity-75 px-2 py-3 lg:py-1 flex items-center text-xs uppercase 
                                font-bold transition-all duration-200 ease-in-out ${linkColor[navColor]}`}
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {text} <i className="ml-1 fas fa-caret-down"></i>
          </a>
          <RenderIf isTrue={render}>
            <div
              className={`absolute left-32 px-8 pb-6 pt-2 bg-white text-sm text-left rounded-lg 
                        shadow-lg transition-all duration-200 ease-in-out transform scale-0 group-hover:scale-100 border w-1/2`}
            >
              <div className="flex flex-wrap w-full">
                {items.map((prop, key) => {
                  return (
                    <div key={key} className="w-full lg:w-1/3 cursor-pointer">
                      <p className="uppercase text-xs px-2 pt-4 pb-1 text-indigo-600 font-black">
                        {prop.title}
                      </p>
                      <div className="pl-0">
                        {prop.items.map((item, itemKey) => {
                          return (
                            <div
                              onClick={() => clickHandler(item)}
                              key={itemKey}
                              className="text-xs text-blueGray-500 px-2 py-1 hover:text-indigo-300 hover:font-black rounded transition-all duration-100"
                            >
                              {item.service}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </>
  );
};

DropdownHoverNavbar.defaultProps = {
  items: [],
};

DropdownHoverNavbar.propTypes = {
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  navColor: PropTypes.oneOf([
    'dark',
    'light',
    'transparent',
    'white',
    'black',
    'blueGray',
    'red',
    'orange',
    'amber',
    'emerald',
    'teal',
    'lightBlue',
    'indigo',
    'purple',
    'pink',
  ]),
};

export default DropdownHoverNavbar;
