const textProps = {
  icon: 'fas fa-rocket',
  iconColor: 'lightBlue',
  title: 'A growing company',
  description: `Our journey has been one of learning and growth. We’ve mastered the digital landscape, 
  enabling us to adapt and thrive. Today, we offer services that help businesses grow and lead in their 
  markets, reflecting the trust our clients place in us.`,
  list: [
    {
      icon: 'fas fa-share-alt',
      text: 'Social Media Mastery',
    },
    {
      icon: 'fas fa-code',
      text: 'Carefully Crafted Software',
    },
    {
      icon: 'fas fa-chart-line',
      text: 'Ensured ROI',
    },
  ],
};
export default textProps;
