import React from 'react';
import photo from 'assets/img/sections/photo-1.jpg';

export default function HeaderBlogPostDark({ data }) {
  const { service, description } = data || {};

  return (
    <>
      <div className="p-0 flex items-center relative min-h-screen-75">
        <div
          className="absolute w-full h-full bg-cover bg-50 z-1"
          style={{ backgroundImage: `url('${photo}')` }}
        ></div>
        <div className="absolute w-full h-full bg-black opacity-40 z-2"></div>
        <div className="container mx-auto px-4 z-3">
          <div className="justify-center text-white flex flex-wrap -mx-4">
            <h1 className="text-4xl font-bold leading-tight mt-0 mb-2 mb-12">
              {service}
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 z-3 -mt-64 pt-20 relative">
        <div className="justify-center text-white flex flex-wrap -mx-4">
          <div className="w-10/12 md:w-3/4 bg-blueGray-800 rounded-lg shadow-lg">
            <div className="text-center -mt-6">
              <div className="bg-blueGray-500 rounded-full text-white w-16 h-16 inline-flex items-center justify-center -mt-2">
                <i className={'p-1 text-xl fa fa-book'}></i>
              </div>
            </div>
            <p className="mb-0 pt-6 pb-12 px-12 leading-relaxed text-center">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
