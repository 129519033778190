import illustrationLinth3 from 'assets/img/illustrations/linth3.svg';
import Christian from 'assets/img/faces/christian.jpg';

const textProps = {
  headImage: illustrationLinth3,
  image: Christian,
  button: {
    children: 'Send Tips',
    color: 'emerald',
    fullWidth: true,
  },
  stats: [
    {
      title: '7,800',
      subtitle: 'Finished Rides',
    },
    {
      title: '4.9/5',
      subtitle: 'Stars',
    },
  ],
  title: 'Christian Paisley',
  description: 'New York, USA',
};

export default textProps;
