const textProps = {
  heading2: {
    color: "teal",
    subtitle: "",
    title: "",
    description:
      "",
    alignment: "center",
    whiteContent: false,
  },
  cards: [
    {
      icon: "website",
      color: "orange",
      title: "Website Development",
      description:
        `Web development ranges from simple static website development to complex web application development, e-commerce, and social networking services.`,
      descriptionExtended: [
        `A website is ideal for your business for many reasons, it serves as a landing platform for customers where you expose all the information, products and services of your business in one place that is easy to access from anywhere. It allows your business to be found on google through searches, it allows it to be used as a final route for marketing campaigns where you can land customers to book services or sell products. It is the main door between your business and the world and the best opportunity to broadcast the message that identifies you and shows why your business is different from the competition and the center of all digital real estate, blogs, forums and everything you can build that gravitates around your business website.`,
        `The expected website development costs can range from $1000 to $300,000. Within this price range, there are levels of website design and development: A constructor-based website will cost up to $1000. A website with average functionalities is expected to cost between $10,000 to $20,000 if crafted by professionals.`
      ],
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "maintenance",
      color: "pink",
      title: "Website Maintenance",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "seo",
      color: "teal",
      title: "Search Engine Optimization",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "blog",
      color: "red",
      title: "Blog Content Creation",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "stripe",
      color: "lightBlue",
      title: "Online Payments",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "datamining",
      color: "amber",
      title: "Data Mining",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "qrbarcodes",
      color: "emerald",
      title: "QR Code Menus",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "mobile",
      color: "lightBlue",
      title: "Mobile App Development",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "pages",
      color: "teal",
      title: "Social Media Pages",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "google",
      color: "red",
      title: "Google Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "youtube",
      color: "red",
      title: "YouTube Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "facebook",
      color: "indigo",
      title: "Facebook Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "instagram",
      color: "pink",
      title: "Instagram Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "whatsapp",
      color: "emerald",
      title: "WhatsApp Business Setup",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "tiktok",
      color: "black",
      title: "TikTok Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "twitter",
      color: "lightBlue",
      title: "Twitter Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "pinterest",
      color: "red",
      title: "Pinterest Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "linkedin",
      color: "lightBlue",
      title: "LinkedIn Campaigns",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "googleAds",
      color: "red",
      title: "Google Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "youtube",
      color: "red",
      title: "YouTube Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "facebook",
      color: "indigo",
      title: "Facebook Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "messenger",
      color: "purple",
      title: "Facebook Messenger Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "instagram",
      color: "pink",
      title: "Instagram Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "tiktok",
      color: "black",
      title: "TikTok Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "twitter",
      color: "lightBlue",
      title: "Twitter Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "pinterest",
      color: "red",
      title: "Pinterest Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },
    {
      icon: "linkedin",
      color: "lightBlue",
      title: "LinkedIn Ads",
      description:
        "You have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s motivating the doers.",
      link: { href: "#pablo", children: "Check more" },
    },

  ],
};
export default textProps;


