import dropdownbutton from '_texts/misc/dropdowns/dropdownbutton.js';
import suitPerson from 'assets/img/person-suit-3.jpg';

const textProps = {
  image: suitPerson,
  title: 'John Darwin',
  description: 'Web Designer',
  list: [
    {
      icon: 'fas fa-hat-wizard',
      color: 'lightBlue',
      leftText: 'Skill',
      rightText: 'Leadership',
    },
    {
      icon: 'far fa-user',
      color: 'lightBlue',
      leftText: 'Hobbies',
      rightText: 'Skiing, Chess',
    },
  ],
  dropdown: {
    ...dropdownbutton,
  },
};

export default textProps;
