import React from 'react';
import { useLocation } from 'react-router-dom';
import NavbarLinks from 'components/Navbars/NavbarLinks.js';
import navbarlinkslanding2 from '_texts/presentation/navbars/navbarlinkslanding2.js';
import Pricing7 from 'components/Sections/Misc/Pricing/Pricing7';
import pricing7 from '_texts/misc/pricing/pricing7';

export default function PricePage() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <NavbarLinks {...navbarlinkslanding2} />
      <div>
        <div className="w-10/12 md:w-7/12 mx-auto pt-32">
          <Pricing7 {...pricing7} />
        </div>

        {/* <Pricing8 {...pricing8} /> */}
      </div>
    </>
  );
}
