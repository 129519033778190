import illustrationSvg from 'assets/img/illustrations/p31.svg';

const textProps = {
  image: illustrationSvg,
  heading2badge: {
    badge: { color: 'purple', children: 'Places to see' },
    title: "Let's Chat!",
    description:
      'Tell us what you need, and we’ll figure out how we can help you reach your goals.',
    alignment: 'center',
    whiteContent: true,
  },
  cards: [
    {
      icon: 'fas fa-bullseye',
      color: 'lightBlue',
      title: 'Precision Targeting',
      description:
        'We specialize in hyper-targeted marketing, ensuring your campaigns reach the exact audience that is most likely to convert.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
    {
      icon: 'fas fa-tags',
      color: 'orange',
      title: 'Niche Expertise',
      description:
        'Our deep understanding of specific niches allows us to craft strategies that resonate with your target market for higher engagement.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
    {
      icon: 'fas fa-handshake',
      color: 'red',
      title: 'Results-Driven Partnership',
      description:
        'We prioritize measurable outcomes with a pay-for-results model, ensuring your investment delivers tangible success.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
    {
      icon: 'fas fa-eye',
      color: 'emerald',
      title: 'Customer Insight',
      description:
        'We track and analyze customer behavior, providing actionable insights to continually refine and optimize your marketing efforts.',
      link: {
        href: '#pablo',
        children: 'Learn more',
      },
    },
  ],
};

export default textProps;
