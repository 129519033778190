import faceFive from 'assets/img/faces/face-5.jpg';
import faceOne from 'assets/img/faces/face-1.jpg';
import faceTwo from 'assets/img/faces/team-2.jpg';
import faceNine from 'assets/img/faces/team-1.jpg';
import faceTen from 'assets/img/faces/face-2.jpg';

const textProps = {
  image: faceFive,
  details: [
    {
      title: 'Tony Mark',
      subtitle: 'Name',
    },
    {
      title: 'Project Manager',
      subtitle: 'Position',
    },
  ],
  stats: [
    {
      title: '2,367',
      subtitle: 'Followers',
    },
    {
      title: '247',
      subtitle: 'Projects',
    },
    {
      title: '1,189',
      subtitle: 'Commits',
    },
  ],
  team: {
    title: 'Team',
    imagesOverlap: {
      size: 'lg',
      images: [
        {
          image: faceOne,
          text: 'Ray Tompson',
        },
        {
          image: faceTwo,
          text: 'Romina Hadid',
        },
        {
          image: faceNine,
          text: 'Alexander Smith',
        },
        {
          image: faceTen,
          text: 'Jessica Doe',
        },
      ],
    },
  },
  button: {
    color: 'lightBlue',
    children: 'Follow',
  },
};

export default textProps;
