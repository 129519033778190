import React from 'react'
import video from "assets/videos/coding.mp4";
import { Card, CardHeader, CardFooter } from "@material-tailwind/react";

const CodeCard = ({ title, subtitle, description, features }) => {
    return (
        <div>
            <div className="container mx-auto px-4 pb-32 pt-12">
                <div className="items-center flex flex-wrap">
                    <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                        <div className="md:pr-12">
                            <h3 className="text-4xl font-black text-blueGray-700">
                                {title}
                            </h3>
                            <h3 className="text-3xl text-indigo-500 font-black mt-2 opacity-80">
                                {subtitle}
                            </h3>
                            {/* <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                {description}
                            </p> */}
                            <ul className="list-none mt-6">
                                {features.map(({ icon, title }) => (
                                    <li className="py-2" key={title}>
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-2.5 px-3 shadow-lg uppercase rounded-full text-blueGray-500 bg-white mr-3">
                                                    <i className={`fas ${icon}`}></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="font-semibold text-blueGray-500">
                                                    {title}
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 mr-auto pt-24 md:pt-0">
                        <Card
                            className="shadow-xl"
                            // style={{ transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)" }}
                        >
                            <CardHeader floated={false}>
                                <video
                                    playsInline="playsinline"
                                    autoPlay="autoplay"
                                    muted="muted"
                                    loop="loop"
                                    className="rounded-lg"
                                >
                                    <source src={video} type="video/mp4" />
                                </video>
                            </CardHeader>
                            <CardFooter>
                                <div className="text-base text-blueGray-500">
                                    {description}
                                </div>
                            </CardFooter>
                        </Card>
                        {/* <img
                            alt="Code Sample Picuture"
                            className="max-w-full rounded-lg shadow-xl"
                            src={image}
                            style={{ transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)" }}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CodeCard