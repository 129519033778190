const textProps = {
  cards: [
    {
      icon: "fab fa-facebook-f",
      title: "facebook",
      subtitle: "@softcro",
      color: "facebook",
      link: {
        href: "https://www.facebook.com/softcro.devs",
      },
    },
    {
      icon: "fab fa-linkedin-in",
      title: "linkedin",
      subtitle: "@softcro",
      color: "slack",
      link: {
        href: "https://www.linkedin.com/company/softcro",
      },
    },
    {
      icon: "fab fa-github",
      title: "github",
      subtitle: "@softcro",
      color: "instagram",
      link: {
        href: "https://github.com/Software-Creative-Operations",
      },
    },
  ],
};
export default textProps;
