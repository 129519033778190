import illustration from "assets/img/illustrations/p7.svg";

const textProps = {
  image: illustration,
  title: "Bussiness Case",
  description:
    "Society has put up so many boundaries and limitations on what’s right and wrong that it’s almost impossible...",
  link: {
    href: "#pablo",
  },
};
export default textProps;
