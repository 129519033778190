import React from 'react';
import { useLocation } from 'react-router-dom';
// sections
import Hero4 from 'components/Sections/Presentation/Heroes/Hero4.js';
import Hero5 from 'components/Sections/Presentation/Heroes/Hero5.js';

import Hero1 from 'components/Sections/Presentation/Heroes/Hero1.js';
import Hero2 from 'components/Sections/Presentation/Heroes/Hero2.js';
import Hero3 from 'components/Sections/Presentation/Heroes/Hero3.js';

import CodeCard from 'components/Sections/Presentation/CodeCard/CodeCard';
// import Blogs1 from "components/Sections/Presentation/Blogs/Blogs1.js";
import Pricing4Presentation from 'components/Sections/Presentation/Pricing/Pricing4Presentation';
// components
import NavbarLinks from 'components/Navbars/NavbarLinks.js';
import HeaderVideoTitle from 'components/Headers/Presentation/HeaderVideoTitle.js';
import PreFooterLarge from 'components/PreFooters/PreFooterLarge.js';
import FooterSmall from 'components/Footers/Landing/FooterSmall.js';
import Faq from 'components/Sections/Presentation/FAQ/Faq.js';
// texts for sections as props
// import blogs1 from "_texts/presentation/blogs/blogs1.js";
import hero4 from '_texts/presentation/heroes/hero4.js';
import hero5 from '_texts/presentation/heroes/hero5.js';

import hero1 from '_texts/presentation/heroes/hero1.js';
import hero2 from '_texts/presentation/heroes/hero2.js';
import hero3 from '_texts/presentation/heroes/hero3.js';

import pricing4presentationProps from '_texts/presentation/pricing/price4presentation.js';
import navbarlinkslanding2 from '_texts/presentation/navbars/navbarlinkslanding2.js';
import headervideotitle from '_texts/presentation/headers/headervideotitle.js';
import prefooterlarge from '_texts/presentation/prefooters/prefooterlarge.js';
import footersmall from '_texts/presentation/footers/footersmall.js';
import codeCardProps from '_texts/presentation/codeCard/codeCard.js';
import faqProps from '_texts/presentation/faq/faq.js';

import testimonials1 from '_texts/e-commerce/sections/testimonials1';
import testimonials2 from '_texts/e-commerce/sections/testimonials2.js';

import Testimonials1 from 'components/Sections/ECommerce/Testimonials/Testimonials1';
import Testimonials2 from 'components/Sections/ECommerce/Testimonials/Testimonials2.js';

export default function Landing2() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <NavbarLinks {...navbarlinkslanding2} />
      <HeaderVideoTitle {...headervideotitle} />
      <div className="mb-24 mt-24">
        <CodeCard {...codeCardProps} />
      </div>
      <div className="container mx-auto px-4 mb-24">
        <Hero5 {...hero5} />
      </div>
      {/* <Pricing4Presentation {...pricing4presentationProps} /> */}
      <div className="container mx-auto px-4">
        <Hero4 {...hero4} />
      </div>
      {/* <div className="container mx-auto px-4">
        <Blogs1 {...blogs1} />
        </div> */}

      {/* <div className="container mx-auto px-4">
      </div> */}
      {/* <Hero1 {...hero1} /> */}

      {/* <div className="container mx-auto px-4">
        <Hero2 {...hero2} />
      </div> */}

      <Faq {...faqProps} />

      {/* <Testimonials1 {...testimonials1} />
      <Testimonials2 {...testimonials2} /> */}

      {/* <div className="container mx-auto px-4">
      </div> */}
      <Hero3 {...hero3} />

      <PreFooterLarge {...prefooterlarge} />
      <FooterSmall {...footersmall} />
    </>
  );
}
