import prefooter from "_texts/blog/prefooters/prefootersmall.js";

const textProps = {
  heading3: {
    color: "light",
    title: "Get to know us better!",
    description:
      "Connect with us on Social Media and let's keep on growing together!",
    alignment: "center",
    whiteContent: true,
  },
  prefooter: { ...prefooter },
};
export default textProps;
