import React from 'react'
import { BrowserRouter } from "react-router-dom";
import Routes from "routes";
import { ThemeProvider } from "@material-tailwind/react";
import { ContextProvider } from "./context";
import { QueryClient, QueryClientProvider } from 'react-query'

const App = () => {

  // React-query client
  const queryClient = new QueryClient()

  return (
    <ThemeProvider>
      <ContextProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </BrowserRouter>
      </ContextProvider>
    </ThemeProvider>
  )
}

export default App