import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// components
import Button from '../../components/Elements/Button.js';

export default function MediaPlayerBlockQuote({ items, defaultOpened }) {
  const [order, setOrder] = useState(items);

  const handleNext = () => {
    const newOrder = [...order.slice(1), order[0]]; // Move the first item to the end
    setOrder(newOrder);
  };

  const handlePrev = () => {
    const newOrder = [order[order.length - 1], ...order.slice(0, -1)]; // Move the last item to the beginning
    setOrder(newOrder);
  };

  const colors = {
    blueGray: 'bg-blueGray-500',
    red: 'bg-red-500',
    orange: 'bg-orange-500',
    amber: 'bg-amber-500',
    emerald: 'bg-emerald-500',
    teal: 'bg-teal-500',
    lightBlue: 'bg-lightBlue-500',
    indigo: 'bg-indigo-500',
    purple: 'bg-purple-500',
    pink: 'bg-pink-500',
  };

  const colorContrast = {
    blueGray: 'bg-blueGray-800',
    red: 'bg-red-800',
    orange: 'bg-orange-800',
    amber: 'bg-amber-800',
    emerald: 'bg-emerald-800',
    teal: 'bg-teal-800',
    lightBlue: 'bg-lightBlue-800',
    indigo: 'bg-indigo-800',
    purple: 'bg-purple-800',
    pink: 'bg-pink-800',
  };

  return (
    <div className="w-8/12 mx-auto mt-12">
      <div className="hidden md:flex justify-between items-center relative">
        <button
          className="bg-lightBlue-500 text-white rounded-full w-16 h-10 p-2 opacity-75 hover:opacity-100 transition-opacity duration-150 ease-linear focus:outline-none"
          onClick={handlePrev}
        >
          <i className="fas fa-chevron-left" />
        </button>
        <div className="flex justify-between items-center space-x-4">
          {order.map((prop, key) => {
            const isCenter = key === 1;
            const isLeft = key === 0;
            const isRight = key === 2;

            return (
              <div
                key={key}
                className={`${
                  isCenter
                    ? 'w-5/12 h-64 scale-110 z-10 mx-12'
                    : isLeft || isRight
                    ? 'w-3/12 h-48 scale-95 z-0'
                    : 'w-32 h-32 scale-90 opacity-75 z-0'
                } transform transition-transform duration-500 ease-in-out relative`}
              >
                <div
                  className={`p-8 rounded-lg shadow-lg bg-opacity-75 ${
                    colors[prop.color]
                  }`}
                >
                  {isCenter && (
                    <button
                      className={`${
                        colorContrast[prop.color]
                      } text-white rounded-full w-16 h-16 absolute -top-8`}
                    >
                      <i className="fas fa-quote-right text-4xl" />
                    </button>
                  )}
                  <p className="my-6 leading-relaxed text-white text-sm">
                    {prop.description}
                  </p>
                  <div className="flex items-center">
                    <img
                      alt={prop.user.name}
                      src={prop.user.image}
                      className="rounded-full mr-4 max-w-40-px"
                    />
                    <h6 className="text-sm font-normal leading-normal mb-2 mt-2 text-white">
                      {prop.user.name}
                    </h6>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <button
          className="bg-lightBlue-500 text-white rounded-full w-16 h-10 p-2 opacity-75 hover:opacity-100 transition-opacity duration-150 ease-linear focus:outline-none"
          onClick={handleNext}
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>

      {/* Display the name and image of the center card */}
      <div className="hidden md:flex justify-center mt-6">
        <img
          alt={order[1].user.name}
          src={order[1].user.image}
          className="rounded-full w-32 h-32 border-8 border-white"
        />
        <div className="flex flex-col justify-center">
          <p className="text-xl text-blueGray-500 ml-4 font-medium">
            {order[1].user.name}
          </p>
          <p className="text-xs text-blueGray-500 font-semibold ml-4">
            {order[1].user.position}
          </p>
        </div>
      </div>

      {/* Mobile view */}
      <div className="md:hidden flex flex-col space-y-2 items-stretch">
        {order.map((prop, key) => (
          <div
            key={key}
            className={`w-full p-4 rounded-lg shadow-lg bg-opacity-75 ${
              colors[prop.color]
            } transform transition-transform duration-500 ease-in-out relative`}
          >
            <p className="my-4 leading-relaxed text-white text-sm">
              {prop.description}
            </p>
            <div className="flex items-center">
              <img
                alt={prop.user.name}
                src={prop.user.image}
                className="rounded-full mr-4 max-w-40-px"
              />
              <h6 className="text-sm font-normal leading-normal mb-2 mt-2 text-white">
                {prop.user.name}
              </h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

MediaPlayerBlockQuote.defaultProps = {
  defaultOpened: 0,
  items: [],
};

MediaPlayerBlockQuote.propTypes = {
  defaultOpened: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      icon: PropTypes.string,
      description: PropTypes.string,
      user: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        position: PropTypes.string,
      }),
      color: PropTypes.oneOf([
        'blueGray',
        'red',
        'orange',
        'amber',
        'emerald',
        'teal',
        'lightBlue',
        'indigo',
        'purple',
        'pink',
      ]),
    })
  ),
};
