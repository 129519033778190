import axios from 'axios';
import { useMutation } from 'react-query';

// remove emailjs-com
// import emailjs from 'emailjs-com'


const sendEmail = (data) =>
  axios.post('https://us-central1-softcro-backend.cloudfunctions.net/sendEmail', data);

const useSendEmail = (options) => useMutation(sendEmail, options);

export default useSendEmail;
