import React, { createContext, useContext } from 'react'

const initialValuae = {
    service: "Website Development",
    setService: () => { },
}

const Context = createContext(initialValuae);

const ContextProvider = ({ children }) => {
    const [service, setService] = React.useState()

    React.useEffect(() => console.log(service), [service])

    return (
        <Context.Provider
            value={{
                service,
                setService,
            }}
        >
            {children}
        </Context.Provider>
    )
}

const useAppContext = () => useContext(Context);

export { ContextProvider, useAppContext };
