import React from 'react';
import Badge from 'components/Elements/Badge.js';

// components
// import Heading3 from '../../../../components/Headings/Heading3.js';
import Heading3Badge from 'components/Headings/Heading3.js';
import InfoAreaIconTitleList from '../../../../components/InfoAreas/Misc/InfoAreaIconTitleList.js';

import Heading3 from '../../../../components/Headings/Heading3.js';

export default function Features3({ heading3badge, infoAreas }) {
  return (
    <div className="relative pt-20">
      <div className="w-10/12 md:w-7/12 mx-auto md:px-4">
        <div className="">
          {/* {heading3badge && <Heading3Badge {...heading3badge} />} */}
          <div className="text-center">
            <Badge color="lightBlue">The Million Ways</Badge>
          </div>
          <Heading3
            title="Proven configurations"
            description="Our services can be customized into any configuration!"
            alignment="center"
          />
        </div>
        <div className="items-start flex flex-wrap -mx-4 md:mt-12">
          {infoAreas.map((prop, key) => (
            <div key={key} className="py-4 relative w-full md:w-4/12">
              <InfoAreaIconTitleList {...prop} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
