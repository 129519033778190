import guitar from "assets/img/guitar-play.jpg";

const textProps = {
  image: guitar,
  subtitle: "All the beautiful places",
  title: "Research by Bang & Olufsen on city sounds and music",
  size: "lg",
  link: { href: "/blog-post" },
};
export default textProps;
