const textProps = {
  text: "Services",
  items: [
    { disabled: true, children: "Website Construction" },
    { href: "#pablo", children: "Website Construction" },
    { href: "#pablo", children: "Website Maintenance" },
    { href: "#pablo", children: "Search Engine Optimization" },
    { href: "#pablo", children: "Google Ads" },
    { href: "#pablo", children: "Blog Content Creation" },
    { href: "#pablo", children: "Payment Integration" },
    { disabled: true, children: "Social Media Marketing" },
    { href: "#pablo", children: "Social Media Pages Setup" },
    { href: "#pablo", children: "Facebook Ads" },
    { href: "#pablo", children: "LinkedIn Ads" },
    { href: "#pablo", children: "YouTube Ads" },
    { disabled: true, children: "Group 3" },
    { href: "#pablo", children: "Demo Page 5" },
    { href: "#pablo", children: "Demo Page 6" },
    { disabled: true, children: "Google Ads" },
    { href: "#pablo", children: "Demo Page 7" },
    { href: "#pablo", children: "Demo Page 8" },
  ],
};
export default textProps;
