import athena from "assets/img/sections/athena.jpg"
import thomas from "assets/img/sections/thomas.jpg";
import twk from "assets/img/sections/twk-tt.jpg";
import odin from "assets/img/sections/odin.jpg";
import kit from "assets/img/sections/kit-suman.jpg";
import damian from "assets/img/sections/damian.jpg";
import victor from "assets/img/sections/victor-garcia.jpg";
import dane from "assets/img/sections/dane.jpg";

const textProps = {
  heading3: {
    color: "indigo",
    subtitle: "daytime",
    title: "8 days out of 7",
    description:
      "Sleep is a luxury sometimes, but then you see who is just flow, and who is a rock. When the darkness of the night wants you, a lot of faces laugh at you, waiting for you to fail.",
    alignment: "center",
  },
  cards: [
    {
      image: athena,
      subtitle: "Stellar Partnership",
      title: "Climate Change in Beautiful Bali",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
    {
      image: thomas,
      subtitle: "Digital Currency",
      title: "Saving the World Starts With You",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
    {
      image: twk,
      subtitle: "A Robot is Your Co-Worker",
      title: "Microcips and Software of the World",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
    {
      image: odin,
      subtitle: "Blockchain Explained",
      title: "Applications Companies",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
    {
      image: kit,
      subtitle: "Sales Leads",
      title: "Best Places to Setup a Company",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
    {
      image: damian,
      subtitle: "AI at the Edge",
      title: "Research for the Images in Summer",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
    {
      image: victor,
      subtitle: "Features",
      title: "Fifty Three Files for Paper",
      link: { href: "#pablo" },
      size: "lg",
      rounded: false,
    },
    {
      image: dane,
      subtitle: "News",
      title: "Focus on Your Employees",
      size: "lg",
      link: { href: "#pablo" },
      rounded: false,
    },
  ],
};
export default textProps;
