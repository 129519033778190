import React, { Fragment } from 'react'

const RenderIf = ({ isTrue, children }) => {
    if (Boolean(isTrue)) {
        return <Fragment>{children}</Fragment>
    }
    return null;
}

export default RenderIf
