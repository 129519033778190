// components/CardContactUsIcons.js
import React, { useState } from 'react';
import { Button } from '@material-tailwind/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../../../components/Elements/Input.js';
import Select from '../../../components/Elements/Select.js';
import useSendEmail from '../../../hooks/useEmailSender.js';

export default function CardContactUsIcons() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    contact_method: '',
    monthly_budget: '',
    goals: '',
  });

  const [errors, setErrors] = useState({});
  const [resetKey, setResetKey] = useState(false); // Flag to reset Select components


  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = 'Name is required';
    if (!formData.email) tempErrors.email = 'Email is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = 'Invalid email format';
    if (!formData.phone) tempErrors.phone = 'Phone is required';
    if (!formData.website) tempErrors.website = 'Website URL is required';
    if (!/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(formData.website)) tempErrors.website = 'Invalid URL format';
    if (!formData.contact_method) tempErrors.contact_method = 'Preferred contact method is required';
    if (!formData.monthly_budget) tempErrors.monthly_budget = 'Monthly budget is required';
    if (!formData.goals) tempErrors.goals = 'Message is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const mutation = useSendEmail({
    onSuccess: () => {
      toast.success('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        website: '',
        contact_method: '',
        monthly_budget: '',
        goals: '',
      });
      setErrors({});
      setResetKey(!resetKey);
    },
    onError: (error) => {
      toast.error(`Failed to send message: ${error.response?.data?.message || error.message}`);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      mutation.mutate(formData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="rounded-lg p-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-2xl z-2">
      <ToastContainer position="bottom-right" />
      <div className="px-4 py-5 flex flex-col">
        <form onSubmit={onSubmit}>
          <div className="flex flex-wrap -mx-4">
            {/* Name Input */}
            <div className="px-4 relative w-full md:w-6/12">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="name"
                >
                  Your Name
                </label>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  leftIcon="fas fa-user-circle"
                  placeholder="ex: John Smith"
                  type="text"
                  id="name"
                />
                {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
              </div>
            </div>

            {/* Email Input */}
            <div className="px-4 relative w-full md:w-6/12">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="email"
                >
                  Email address
                </label>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  leftIcon="fas fa-envelope"
                  placeholder="Email address..."
                  type="email"
                  id="email"
                />
                {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
              </div>
            </div>

            {/* Phone Input */}
            <div className="px-4 relative w-full md:w-6/12">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <Input
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  leftIcon="fas fa-phone"
                  placeholder="ex: 123-456-7890"
                  type="text"
                  id="phone"
                />
                {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
              </div>
            </div>

            {/* Website Input */}
            <div className="px-4 relative w-full md:w-6/12">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="website"
                >
                  Website URL
                </label>
                <Input
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  leftIcon="fas fa-link"
                  placeholder="ex: https://example.com"
                  type="text"
                  id="website"
                />
                {errors.website && <p className="text-red-500 text-xs">{errors.website}</p>}
              </div>
            </div>

            {/* Preferred Contact Method Select */}
            <div className="px-4 relative w-full md:w-6/12">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="contact_method"
                >
                  Preferred Contact Method
                </label>
                <Select
                  items={['Call', 'Email', 'Text']}
                  placeholder="Select Contact Method"
                  value={formData.contact_method}
                  resetKey={resetKey}
                  onChange={(value) => setFormData({ ...formData, contact_method: value })}
                />
                {errors.contact_method && (
                  <p className="text-red-500 text-xs">{errors.contact_method}</p>
                )}
              </div>
            </div>

            {/* Projected Monthly Budget Select */}
            <div className="px-4 relative w-full md:w-6/12">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="monthly_budget"
                >
                  Projected Monthly Budget
                </label>
                <Select
                  items={[
                    'under $2,000',
                    '$2,000 - $5,000',
                    '$5,000 - $10,000',
                    '$10,000 - $20,000',
                    '$20,000 - $50,000',
                    '$50,000 - $100,000',
                    '$100,000+',
                  ]}
                  placeholder="Select Budget"
                  value={formData.monthly_budget}
                  resetKey={resetKey}
                  onChange={(value) => setFormData({ ...formData, monthly_budget: value })}
                />
                {errors.monthly_budget && (
                  <p className="text-red-500 text-xs">{errors.monthly_budget}</p>
                )}
              </div>
            </div>

            {/* Goals Input */}
            <div className="px-4 relative w-full">
              <div className="pb-4">
                <label
                  className="inline-block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1"
                  htmlFor="goals"
                >
                  Your message
                </label>
                <Input
                  name="goals"
                  value={formData.goals}
                  onChange={handleChange}
                  type="textarea"
                  rows={6}
                  id="goals"
                  placeholder="ex: I need to increase online presence and lead generation."
                />
                {errors.goals && <p className="text-red-500 text-xs">{errors.goals}</p>}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-center">
            <Button type="submit" disabled={mutation.isLoading}>
              {mutation.isLoading ? 'Sending...' : 'Contact Us'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
