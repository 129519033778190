import dropdownnavbar from '_texts/misc/dropdowns/dropdownnavbar.js';

const textProps = {
  // color: "dark",
  type: 'fixed',
  logoText: 'Softcro',
  logoLink: {
    to: '/',
  },
  socials: [
    {
      icon: 'facebook',
      text: 'Share on Facebook',
      link: 'https://www.facebook.com/softcro.devs',
    },
    {
      icon: 'linkedin',
      text: 'Start of LinkedIn',
      link: 'https://www.linkedin.com/company/softcro',
    },
    {
      icon: 'github',
      text: 'Star on Github',
      link: 'https://github.com/Software-Creative-Operations',
    },
  ],
  leftLinks: [
    {
      to: '/about',
      children: 'About Us',
    },
    {
      to: '/contact',
      children: 'Contact',
    },
    {
      to: '/pricing',
      children: 'Pricing',
    },
    // {
    //   to: "/blog-posts",
    //   children: "Blog",
    // },
    {
      dropdown: true,
      ...dropdownnavbar,
    },
  ],
};
export default textProps;
