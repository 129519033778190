import React from 'react'
import { Route, Switch } from "react-router-dom";
// Docs Routes - START - you can delete these when you no longer need our docs
import Components from "_docs/layouts/Components.js";
import Documentation from "_docs/layouts/Documentation.js";
// Docs Routes -  STOP

// Product Pages - START
// // admin
import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings2 from "views/admin/Settings2.js";
import Tables from "views/admin/Tables.js";
// // auth
import Login1 from "views/auth/Login1.js";
import Login2 from "views/auth/Login2.js";
import Register1 from "views/auth/Register1.js";
import Register2 from "views/auth/Register2.js";
import Reset from "views/auth/Reset.js";
// // blog
import BlogPost from "views/blog/BlogPost.js";
import BlogPosts from "views/blog/BlogPosts.js";
// // e-commerce
import Chat from "views/e-commerce/Chat.js";
import Checkout from "views/e-commerce/Checkout.js";
import ECommerce from "views/e-commerce/ECommerce.js";
import Invoice from "views/e-commerce/Invoice.js";
import Pricing from "views/e-commerce/Pricing.js";
import Product from "views/e-commerce/Product.js";
import Profile1 from "views/e-commerce/Profile1.js";
import Settings1 from "views/e-commerce/Settings1.js";
import Services from "views/e-commerce/Services.js";
// // error
import Error404 from "views/error/Error404.js";
import Error500 from "views/error/Error500.js";
// // presentation
import Landing1 from "views/presentation/Landing1.js";
import Landing2 from "views/presentation/Landing2.js";
import AboutUs from "views/presentation/AboutUs.js";
import ContactUs from "views/presentation/ContactUs.js";
import PricePage from "views/presentation/PricePage";
// Product Pages - STOP

const Routes = () => {
    return (
        <Switch>
            {/* Product Pages - START */}
            {/* admin */}
            <Route
                path="/dashboard"
                exact
                render={() => <Dashboard />}
            />
            <Route
                path="/maps"
                exact
                render={() => <Maps />}
            />
            <Route
                path="/settings-2"
                exact
                render={() => <Settings2 />}
            />
            <Route
                path="/tables"
                exact
                render={() => <Tables />}
            />
            {/* auth */}
            <Route
                path="/login-1"
                exact
                render={() => <Login1 />}
            />
            <Route
                path="/login-2"
                exact
                render={() => <Login2 />}
            />
            <Route
                path="/register-1"
                exact
                render={() => <Register1 />}
            />
            <Route
                path="/register-2"
                exact
                render={() => <Register2 />}
            />
            <Route
                path="/reset"
                exact
                render={() => <Reset />}
            />
            {/* blog */}
            <Route
                path="/blog-post"
                exact
                render={() => <BlogPost />}
            />
            <Route
                path="/blog-posts"
                exact
                render={() => <BlogPosts />}
            />
            {/* e-commerce */}
            <Route
                path="/chat"
                exact
                render={() => <Chat />}
            />
            <Route
                path="/checkout"
                exact
                render={() => <Checkout />}
            />
            <Route
                path="/e-commerce"
                exact
                render={() => <ECommerce />}
            />
            <Route
                path="/invoice"
                exact
                render={() => <Invoice />}
            />
            <Route
                path="/pricing"
                exact
                render={() => <PricePage />}
            />
            <Route
                path="/product"
                exact
                render={() => <Product />}
            />
            <Route
                path="/profile-1"
                exact
                render={() => <Profile1 />}
            />
            <Route
                path="/settings-1"
                exact
                render={() => <Settings1 />}
            />
            {/* error */}
            <Route
                path="/error-404"
                exact
                render={() => <Error404 />}
            />
            <Route
                path="/error-500"
                exact
                render={() => <Error500 />}
            />
            {/* presentation */}
            <Route
                path="/landing-1"
                exact
                render={() => <Landing1 />}
            />
            <Route
                path="/landing-2"
                exact
                render={() => <Landing2 />}
            />
            <Route
                path="/about"
                exact
                render={() => <AboutUs />}
            />
            <Route
                path="/services"
                exact
                render={() => <Services />}
            />
            <Route
                path="/contact"
                exact
                render={() => <ContactUs />}
            />
            {/* Product Pages - STOP */}
            {/* Docs Routes - START - you can delete these when you no longer need our docs */}
            <Route path="/components" component={Components} />
            <Route path="/documentation" component={Documentation} />
            {/* Docs Routes - STOP */}
            <Route
                path="/"
                exact
                render={() => <Landing2 />}
            />
        </Switch>
    )
}

export default Routes