import Input1 from "./Input1.js";
import Input2 from "./Input2.js";
import Input3 from "./Input3.js";
import Input4 from "./Input4.js";
import Input5 from "./Input5.js";
import Input6 from "./Input6.js";
import Input7 from "./Input7.js";
import Input8 from "./Input8.js";
import Input9 from "./Input9.js";
import Input10 from "./Input10.js";
import Input11 from "./Input11.js";
import Input12 from "./Input12.js";
import Input13 from "./Input13.js";
import Input14 from "./Input14.js";
import Input15 from "./Input15.js";
import Input16 from "./Input16.js";
import Input17 from "./Input17.js";
import Input18 from "./Input18.js";
import Input19 from "./Input19.js";
import Input20 from "./Input20.js";

const componentsArray = [
  Input1,
  Input2,
  Input3,
  Input4,
  Input5,
  Input6,
  Input7,
  Input8,
  Input9,
  Input10,
  Input11,
  Input12,
  Input13,
  Input14,
  Input15,
  Input16,
  Input17,
  Input18,
  Input19,
  Input20,
];
export default componentsArray;
