import React from "react";
import { useLocation } from "react-router-dom";
// sections
import Projects1 from "components/Sections/ECommerce/Projects/Projects1.js";
import Hero6 from "components/Sections/Blog/Heroes/Hero6.js";
// components
import NavbarLinks from "components/Navbars/NavbarLinks.js";
import HeaderBlogPost from "components/Headers/ECommerce/HeaderBlogPost.js";
import PreFooterLarge from "components/PreFooters/PreFooterLarge.js";
import FooterSmall from "components/Footers/Landing/FooterSmall.js";
// texts as props
import navbarlinkspricing from "_texts/e-commerce/navbars/navbarlinkspricing.js";
import headerblogpost from "_texts/e-commerce/headers/headerblogpost.js";
import projects1 from "_texts/e-commerce/projects/projects1.js";
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import footersmall from "_texts/presentation/footers/footersmall.js";

export default function Services() {
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            <div>
                <NavbarLinks {...navbarlinkspricing} />
                <HeaderBlogPost {...headerblogpost} />
            </div>
            <div className="mt-12 main">
                {/* <Pricing2 {...pricing2} /> */}
                <Projects1 {...projects1} />
            </div>
            <PreFooterLarge {...prefooterlarge} />
            <FooterSmall {...footersmall} />
        </>
    );
}
