const textProps = {
  heading3badge: {
    badge: { color: 'purple', children: 'All Sectors' },
    title: 'Suitable for Every Sector',
    description:
      "No matter where your business falls within the economy—whether you're in the Retail, Services, or Industrial/Manufacturing sector—our marketing services are tailored to meet your unique needs.",
    alignment: 'left',
  },
  infoAreas: [
    {
      icon: 'fas fa-search',
      color: 'lightBlue',
      title: 'SEO',
      description:
        'Search Engine Optimization (SEO) improves website visibility in search engines, increasing organic traffic.',
      list: [
        {
          icon: 'fas fa-check',
          text: 'Local SEO',
        },
        {
          icon: 'fas fa-check',
          text: 'National SEO',
        },
        {
          icon: 'fas fa-check',
          text: 'Enterprise SEO',
        },
      ],
    },
    {
      icon: 'fas fa-money-bill-wave',
      color: 'lightBlue',
      title: 'Paid Media',
      description:
        'Paid media involves purchasing advertisements to promote content, products, or services.',
      list: [
        {
          icon: 'fas fa-check',
          text: 'Google Ads Management',
        },
        {
          icon: 'fas fa-check',
          text: 'Social Media Advertising',
        },
        {
          icon: 'fas fa-check',
          text: 'Lead Generation',
        },
      ],
    },
    {
      icon: 'fas fa-bullhorn',
      color: 'lightBlue',
      title: 'Brand & Content',
      description:
        'Brand & Content involves creating consistent, engaging messages that build brand identity and loyalty.',
      list: [
        {
          icon: 'fas fa-check',
          text: 'Web Design and Development',
        },
        {
          icon: 'fas fa-check',
          text: 'Content Marketing',
        },
        {
          icon: 'fas fa-check',
          text: 'Conversion Rate Optimization',
        },
      ],
    },
    {
      icon: 'fas fa-broadcast-tower',
      color: 'lightBlue',
      title: 'Marketing Amplification',
      description:
        'Marketing Amplification expands the reach of efforts through targeted channels.',
      list: [
        {
          icon: 'fas fa-check',
          text: 'Social Media Management',
        },
        {
          icon: 'fas fa-check',
          text: 'Email Marketing',
        },
        {
          icon: 'fas fa-check',
          text: 'Influencer Marketing',
        },
      ],
    },
    {
      icon: 'fas fa-paint-brush',
      color: 'lightBlue',
      title: 'Creative',
      description:
        'Creative services craft digital content that enhances brand identity and engagement.',
      list: [
        {
          icon: 'fas fa-check',
          text: 'Graphic Design',
        },
        {
          icon: 'fas fa-check',
          text: 'UX/UI Design',
        },
        {
          icon: 'fas fa-check',
          text: 'Video Marketing',
        },
      ],
    },
    {
      icon: 'fas fa-laptop-code',
      color: 'lightBlue',
      title: 'Software & Automation',
      description:
        'The development of custom and crafted software that provide business solutions.',
      list: [
        {
          icon: 'fas fa-check',
          text: 'Application Development',
        },
        {
          icon: 'fas fa-check',
          text: 'CRM Integration & Customization',
        },
        {
          icon: 'fas fa-check',
          text: 'AI Software Integration',
        },
      ],
    },
  ],
};

export default textProps;
