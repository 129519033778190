import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  CardHeader,
  CardBody,
} from "@material-tailwind/react";
import Heading2Badge from "../../../../components/Headings/Heading2Badge.js";


export default function Hero5({ heading2badge, infoAreas }) {
  return (
    <div className="pb-12">
      {heading2badge && <Heading2Badge {...heading2badge} />}
      <div className="w-full flex flex-wrap justify-center md:-mx-4 mt-8">
        {infoAreas.map((prop, key) => {
          return (
            <Card
              className="text-left m-2 mt-12 md:mt-2 relative w-full lg:w-1/4 border border-1 shadow-xl"
              key={key}
            >
              <CardHeader
              >
                <img
                  src={prop.picture}
                  alt="img-blur-shadow"
                  className="w-full h-full"
                />
              </CardHeader>
              <CardBody>
                <p className="text-blueGray-500">
                  {prop.description}
                </p>
              </CardBody>

            </Card>
          );
        })}
      </div>
    </div>
  );
}

Hero5.defaultProps = {
  infoAreas: [],
  heading2badge: null,
};
Hero5.propTypes = {
  // prop to pass to the Heading2Badge component
  heading2badge: PropTypes.object,
  // an array of props that can be passed to the
  // InfoAreaIconTitleLinkColor component
  infoAreas: PropTypes.arrayOf(PropTypes.object),
};
