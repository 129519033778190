import mediaplayerblockquote from '_texts/misc/mediaplayers/mediaplayerblockquote.js';

const textProps = {
  heading2: {
    color: 'lightBlue',
    subtitle: 'Our Customers',
    title: 'What Our Clients Say',
    description:
      "Our clients' success stories speak volumes. From boosting online visibility to driving sales growth, our tailored strategies have transformed businesses across various industries.",
    alignment: 'center',
  },
  mediaplayer: { ...mediaplayerblockquote },
};

export default textProps;
