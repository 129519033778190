import research from "assets/img/research.jpg";
import work from "assets/img/work.jpg";
import success from "assets/img/success.jpg";


const textProps = {
  heading2badge: {
    badge: {
      color: "pink",
      children: "The How",
      round: false
    },
    title: "How We Do It",
    description:
      "We Are With You From Concept to Deployment",
    alignment: "center",
  },
  infoAreas: [
    {
      picture: research,
      description: `Our team conducts thorough research and analysis to understand the needs and goals of your business, ensuring a tailored software solution.`,
    },
    {
      picture: work,
      description: `With a focus on execution and implementation, our skilled developers bring your software vision to life with innovative technology.`,
    },
    {
      picture: success,
      description: `Experience the benefits of a seamless and efficient software solution, designed to enhance your business operations and drive growth.`,
    },
  ],
};
export default textProps;
