import sofia from "assets/img/sections/sofia-kuniakina.jpg";
import sacha from "assets/img/sections/sacha-styles.jpg";
import victor from "assets/img/sections/victor-garcia.jpg";
import doyoun from "assets/img/sections/doyoun-seo.jpg";
import ayo from "assets/img/sections/ayo-ogunseinde.jpg";

const textProps = {
  defaultOpened: 0,
  size: "sm",
  items: [
    sofia,
    sacha,
    victor,
    doyoun,
    ayo,
  ],
};
export default textProps;
