const textProps = {
  image: require("assets/img/ecommerce-1.jpg").default,
  icon: "fas fa-calendar-check",
  color: "red",
  text: "Wearing",
  description:
    "Yesterday, this was an essential fabric and its news reader app Paper. It is design-focused wool called out...",
  title: "Check our latest collection",
  link: {
    href: "#pablo",
  },
};
export default textProps;
