import navbarlinkslanding1 from "_texts/presentation/navbars/navbarlinkslanding1.js";
import prefooterlarge from "_texts/presentation/prefooters/prefooterlarge.js";
import footersmall from "_texts/presentation/footers/footersmall.js";

const textProps = {
  color: "purple-indigo",
  navbar: { ...navbarlinkslanding1 },
  prefooter: { ...prefooterlarge },
  footer: { ...footersmall },
};
export default textProps;
